import { ISortBy } from 'react-ui-kit-exante';

export const DISPLAYED_COLUMN_KEYS = [
  'id',
  'position_type',
  'underlying',
  'ul_up',
  'ul_down',
  'iv_up',
  'iv_down',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
export const DATE_WITH_TIME = 'yyyy-MM-dd, HH:mm';

export const getDefaultPagination = () => ({
  limit: PAGE_SIZE,
  skip: 0,
});

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'updated_at', desc: true }];
}
export const DEFAULT_SORTING = getDefaultSorting();

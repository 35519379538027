import { Table, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: '20px',
}));

export const TableContainer = styled(Table)(() => ({
  paddingBottom: '20px',

  '& .Table': {
    overflow: 'visible',
  },
}));

export const NoDataContainer = styled('div')(({ theme }) => ({
  color: theme.color.typo.warning,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px',
}));

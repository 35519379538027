import { Controller } from 'react-hook-form';
import { Autocomplete, Checkbox, IconButton, Input } from 'react-ui-kit-exante';

import { DAYS_OF_WEEK } from 'constants/periodicJob';

import { CONTROL_CLS } from './constants';
import { useRuntimeSettingAdd } from './hooks';
import { ActionsContainer, ControlsContainer, PanelContainer } from './styled';

export const RuntimeSettingAdd = () => {
  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  } = useRuntimeSettingAdd();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  const runOnDaysOptions = DAYS_OF_WEEK.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer title="Add Runtime Setting" action={renderActions()} />

      <ControlsContainer>
        <Controller
          name="account"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Account ID"
              sx={CONTROL_CLS}
              warning={dirtyFields.account}
              error={Boolean(errors.account)}
              message={errors.account?.message}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Name"
              sx={CONTROL_CLS}
              warning={dirtyFields.name}
              error={Boolean(errors.name)}
              message={errors.name?.message}
            />
          )}
        />
        <Controller
          name="isEnabled"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              label="Enable runtime setting"
              sx={CONTROL_CLS}
            />
          )}
        />
        <Controller
          name="startTime"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputProps={{
                placeholder: 'hh:mm',
              }}
              label="Start Time"
              sx={CONTROL_CLS}
              warning={dirtyFields.startTime}
              error={Boolean(errors.startTime)}
              message={errors.startTime?.message}
            />
          )}
        />
        <Controller
          name="runOnDays"
          control={control}
          render={({ field }) => {
            const onChange = (
              _: React.ChangeEvent<EventTarget>,
              newValue: Record<string, string>[],
            ) => field.onChange(newValue);

            return (
              <Autocomplete
                {...field}
                isMultiple
                value={field.value}
                onChange={onChange}
                isOptionEqualToValue={(option, selectedValue) =>
                  option.value === selectedValue.value
                }
                options={runOnDaysOptions}
                controlProps={{
                  placeholder: 'Run On Days',
                  error: Boolean(errors.runOnDays),
                  message: errors?.runOnDays?.message,
                  warning: dirtyFields.runOnDays as boolean | undefined,
                }}
                sx={CONTROL_CLS}
              />
            );
          }}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Comment"
              sx={{
                ...CONTROL_CLS,
                width: '100%',
              }}
              warning={dirtyFields.comment}
              error={Boolean(errors.comment)}
              message={errors.comment?.message}
            />
          )}
        />
      </ControlsContainer>
    </form>
  );
};

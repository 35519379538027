import { array, boolean, object, string } from 'yup';

export const VALIDATION_SCHEMA = object().shape({
  account: string().nullable(),
  comment: string().nullable(),
  isEnabled: boolean().nullable(),
  name: string().required('Name is a required field'),
  runOnDays: array().min(1).required('Run On Days is a required field'),
  startTime: string()
    .required('Start Time is a required field')
    .matches(/^[0-9]{2}:[0-9]{2}$/, 'Start Time must be in format 12:00'),
});

import type { TPeriodicJobCreate } from 'types';

export const CONTROL_CLS = { mt: '16px', width: '290px' };
export const PERCENT = 100;

export const DEFAULT_VALUES: TPeriodicJobCreate = {
  account: '',
  comment: '',
  isEnabled: true,
  name: '',
  runOnDays: [],
  startTime: '',
};

import { IColumn } from 'react-ui-kit-exante';

import type { TExclude, TOption } from 'types';

export type TGetColumnsProps = {
  exclusionTypeOptions: TOption[];
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
};

export const getColumns = ({
  exclusionTypeOptions,
  onFilter,
  onRemove,
}: TGetColumnsProps): IColumn<TExclude>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
  },
  {
    Header: 'Exclusion Type',
    accessor: 'type',
    editParams: {
      editable: true,
      inputType: 'select',
      options: exclusionTypeOptions,
    },
    filterOptions: exclusionTypeOptions,
    type: 'select',
    onFilter,
    onRemove,
  },
  {
    Header: 'Value',
    accessor: 'value',
    editParams: {
      editable: true,
      inputType: 'text',
    },
    onFilter,
    onRemove,
  },
];

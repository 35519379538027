import { ICellValue, IColumn } from 'react-ui-kit-exante';

import type { TFactor } from 'types';
import { formatNumberToFinancial } from 'utils';

import { CommentCell } from '../CommentCell';

export const getColumns = (): IColumn<TFactor> => [
  {
    Header: 'Factor / Client',
    id: 'factorClientOrAccount',
    accessor: 'factorClientOrAccount',
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      const { factor, clientOrAccount } = original;

      if (factor) {
        return factor;
      }

      return clientOrAccount;
    },
  },
  {
    Header: 'Scenario Delta',
    id: 'scenarioDelta',
    accessor: 'scenarioDelta',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      if (typeof original.scenarioDelta !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioDelta);
    },
  },
  {
    Header: 'Scenario IV',
    id: 'scenarioIv',
    accessor: 'scenarioIv',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      if (typeof original.scenarioIv !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioIv);
    },
  },
  {
    Header: 'Scenario PNL',
    id: 'scenarioPnl',
    accessor: 'scenarioPnl',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      if (typeof original.scenarioPnl !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioPnl);
    },
  },
  {
    Header: 'VaR',
    id: 'var',
    accessor: 'var',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      if (typeof original.var !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.var);
    },
  },
  {
    Header: 'Comment',
    id: 'comment',
    accessor: 'comment',
    disableSortBy: true,
    shouldExpand: false,
    Cell: ({ row: { original } }: ICellValue<TFactor>) => {
      const { comment, summaryId } = original;

      return <CommentCell comment={comment} id={summaryId} type="summary" />;
    },
  },
];

import { AxiosError } from 'axios';

import { StressTestJobsState } from '../types';

export const onFetchManualTestError = (
  state: StressTestJobsState,
  error: AxiosError,
): StressTestJobsState => {
  return {
    ...state,

    ready: true,

    manualTestData: [],

    fetchManualTestStatus: {
      error,
      pending: false,
      succeed: false,
    },
  };
};

import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

interface IGetAdditionalFiltersProps {
  filters: Record<string, unknown>;
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  defaultFilters: string[];
}

export function getAdditionalFilters<Data extends Record<string, unknown>>({
  filters,
  onFilter,
  onRemove,
  defaultFilters,
}: IGetAdditionalFiltersProps): TAdditionalFilter<Data>[] {
  let [fromDate, toDate] = defaultFilters;

  if (filters.created_at) {
    const [createTimeFrom, createTimeTo] = filters.created_at as string[];
    const isCreateTimeFromYear1970 =
      new Date(createTimeFrom).getFullYear() === 1970;
    const isCreateTimeToYear1970 =
      new Date(createTimeTo).getFullYear() === 1970;

    if (isCreateTimeFromYear1970) {
      onFilter('created_at', [fromDate, toDate]);
    } else {
      fromDate = createTimeFrom;
    }

    if (isCreateTimeToYear1970) {
      onFilter('created_at', [fromDate, toDate]);
    } else {
      toDate = createTimeTo;
    }
  }

  return [
    {
      Header: 'Created At',
      accessor: 'created_at',
      type: 'dateRange',
      filterValue: [fromDate, toDate],
      onFilter,
      onRemove,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
    },
  ];
}

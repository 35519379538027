import { StressTestSettingsActions } from './actions';
import {
  onParametersToggleRemoveConfigModal,
  onToggleRemoveExcludeModal,
  onToggleRemoveSegregatedAccountModal,
  onToggleRuntimeSettingRemoveModal,
} from './handlers';
import type {
  StressTestSettingsReducer,
  StressTestSettingsState,
} from './types';

export const reducer: StressTestSettingsReducer = (
  state,
  action,
): StressTestSettingsState => {
  switch (action.type) {
    /**
     * Stress Test Settings
     */
    case StressTestSettingsActions.ParametersRemoveConfigModal:
      return onParametersToggleRemoveConfigModal(state, action.payload);

    case StressTestSettingsActions.RemoveExcludeModal:
      return onToggleRemoveExcludeModal(state, action.payload);

    case StressTestSettingsActions.RemoveSegregatedAccountModal:
      return onToggleRemoveSegregatedAccountModal(state, action.payload);

    /**
     * Runtime Setting
     */
    case StressTestSettingsActions.RuntimeSettingRemoveModal:
      return onToggleRuntimeSettingRemoveModal(state, action.payload);

    default:
      return state;
  }
};

import type { TSymbolIdInfoParams } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { SymbolRepository } from './symbol.repository';

export class SymbolService {
  public getSymbolIdInfo = async (params: TSymbolIdInfoParams) => {
    try {
      const { data } = await SymbolRepository.fetchSymbolIdInfo(params);

      return data.data;
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  };
}

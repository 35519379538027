import { styled } from 'react-ui-kit-exante';

export const InputContainer = styled('input')(({ theme }) => ({
  backgroundColor: theme.color.input.bg.default,
  border: `1px solid ${theme.color.input.border}`,
  borderRadius: '4px',
  maxWidth: '200px',
  outline: 'none',
  padding: '4px 12px',

  '&:focus': {
    backgroundColor: theme.color.input.bg.hover,
    borderColor: theme.color.input.warning,
  },
}));

export const CommentContainer = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}));

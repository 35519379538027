import { SyntheticEvent, useCallback, useContext, useState } from 'react';

import { DEFAULT_TRADE_VALUE } from 'constants/manualTest';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { symbolService } from 'resources';
import type { TOption } from 'types/common';

import { AutocompleteAsyncContainer } from './styled';

type TVirtualPositionProps = {
  accountName: string;
  date: string;
  quantity: number | null;
  tradeName: string;
};

export const VirtualPosition = ({
  accountName,
  date,
  quantity,
  tradeName,
}: TVirtualPositionProps) => {
  const [options, setOptions] = useState<TOption[]>([]);
  const [tradeValue, setTradeValue] = useState(tradeName);

  const { dispatch } = useContext(StressTestJobsContext);

  const getTradesAutocompleteFn = useCallback(async (search = '') => {
    const payload = {
      limit: 100,
      search,
      skip: 0,
    };

    const data = await symbolService.getSymbolIdInfo(payload);

    const tradesOptions = data?.length
      ? data.map((item) => ({
          label: item.name,
          value: item.name,
        }))
      : [];

    setOptions(tradesOptions);

    return {
      fetchData: getTradesAutocompleteFn,
      options: tradesOptions,
    };
  }, []);

  const handleAutocompleteChange = (
    _: SyntheticEvent<Element, Event>,
    option: TOption,
  ) => {
    const { value = '' } = option || {};

    dispatch({
      type: StressTestJobsActions.SetVirtualPosition,
      payload: {
        accountName,
        date,
        tradeName: value,
        quantity,
      },
    });

    setTradeValue(value);
  };

  return (
    <AutocompleteAsyncContainer
      className="AutoCompleteSmall"
      fetchData={getTradesAutocompleteFn}
      fullWidth
      onChange={handleAutocompleteChange}
      options={options}
      placeholder="Virtual Position"
      size="small"
      value={tradeValue === DEFAULT_TRADE_VALUE ? '' : tradeValue}
    />
  );
};

import { DAYS_OF_WEEK } from 'constants/periodicJob';
import type { TPeriodicJob, TPeriodicJobServer } from 'types';

const prepareStartTime = (periodicJob: TPeriodicJobServer): string => {
  return `${String(periodicJob.hour[0]).padStart(2, '0')}:${String(
    periodicJob.minute[0],
  ).padStart(2, '0')}`;
};

const transformDaysOfWeek = (periodicJob: TPeriodicJobServer): string[] => {
  return periodicJob.day_of_week.map((item) => DAYS_OF_WEEK[Number(item) - 1]);
};

export const normalizeData = (
  results: TPeriodicJobServer[],
): TPeriodicJob[] => {
  return results.map((periodicJob) => {
    return {
      account: periodicJob.account,
      arguments: periodicJob.arguments,
      comment: periodicJob.comment,
      id: periodicJob.id,
      isEnabled: periodicJob.is_enabled,
      name: periodicJob.name,
      runOnDays: transformDaysOfWeek(periodicJob),
      startTime: prepareStartTime(periodicJob),
    };
  });
};

import { useCallback, useContext } from 'react';
import { IconButton, Notification, Tooltip } from 'react-ui-kit-exante';

import { transformData } from 'pages/StressTestJobs/ManualTest/helpers';
import { ActionsContainer } from 'pages/StressTestJobs/ManualTest/styled';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';

type TSaveVirtualPositionProps = {
  accountName: string;
  date: string;
  getLaunchParams: () => Promise<void>;
};

export const SaveVirtualPosition = ({
  accountName,
  date,
  getLaunchParams,
}: TSaveVirtualPositionProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const { manualTestData, virtualPositionValue } = state;

  const handleSaveTrade = useCallback(async () => {
    if (
      virtualPositionValue.quantity === null ||
      Number.isNaN(Number(virtualPositionValue.quantity))
    ) {
      Notification.warning({ title: 'Quantity is not a valid number' });

      return;
    }

    if (virtualPositionValue.quantity === 0) {
      Notification.warning({ title: 'Quantity 0 is not applicable' });

      return;
    }

    const transformedPayload = transformData(manualTestData).map((item) => {
      if (item.account === accountName && item.date === date) {
        return {
          ...item,
          trades: {
            ...item.trades,
            [virtualPositionValue.tradeName]: virtualPositionValue.quantity,
          },
        };
      }

      return item;
    });

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    dispatch({
      type: StressTestJobsActions.RemoveVirtualPosition,
    });

    Notification.success({ title: 'Successfully added position' });
  }, [manualTestData, virtualPositionValue]);

  const isDirty =
    accountName === virtualPositionValue.accountName &&
    virtualPositionValue.tradeName &&
    date === virtualPositionValue.date;

  return (
    <ActionsContainer>
      <Tooltip title="Save position">
        <IconButton
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={18}
          onClick={handleSaveTrade}
        />
      </Tooltip>
    </ActionsContainer>
  );
};

import { DefaultThemeProvider } from 'theme';

import { StyledTab } from './styled';
import { IUITabProps } from './types';

export const Tab = <T,>({ children, label, ...rest }: IUITabProps & T) => {
  return (
    <DefaultThemeProvider>
      <StyledTab disableRipple label={label} {...rest} />
    </DefaultThemeProvider>
  );
};

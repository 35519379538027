import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { segregatedAccountService } from 'resources';
import type { TSegregatedAccountPayload } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { DEFAULT_VALUES } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

export const useSegregatedAccountAdd = () => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(ROUTES.STRESS_TEST_SETTINGS);
  }, [navigate]);

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
  } = methods;

  const onSubmit = async (createPayload: TSegregatedAccountPayload) => {
    try {
      await segregatedAccountService.createSegregatedAccount(createPayload);

      Notification.success({
        title: 'Segregated Account successfully created',
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  return {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  };
};

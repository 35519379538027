import { IColumn } from 'react-ui-kit-exante';

import type { TSegregatedAccount } from 'types';

export type TGetColumnsProps = {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
};

export const getColumns = ({
  onFilter,
  onRemove,
}: TGetColumnsProps): IColumn<TSegregatedAccount>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
  },
  {
    Header: 'Account',
    accessor: 'account',
    editParams: {
      editable: true,
      inputType: 'text',
    },
    required: true,
    onFilter,
    onRemove,
  },
];

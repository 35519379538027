import { Controller } from 'react-hook-form';
import { IconButton, Input } from 'react-ui-kit-exante';

import { CONTROL_CLS } from './constants';
import { useSegregatedAccountAdd } from './hooks';
import { ActionsContainer, ControlsContainer, PanelContainer } from './styled';

export const SegregatedAccountAdd = () => {
  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  } = useSegregatedAccountAdd();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer title="Add Segregated Account" action={renderActions()} />

      <ControlsContainer>
        <Controller
          name="account"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Account"
              sx={CONTROL_CLS}
              warning={dirtyFields.account}
              error={Boolean(errors.account)}
              message={errors.account?.message}
            />
          )}
        />
      </ControlsContainer>
    </form>
  );
};

import { StressTestSettingsState, TRemoveConfig } from '../types';

export const onParametersToggleRemoveConfigModal = (
  state: StressTestSettingsState,
  payload: TRemoveConfig,
): StressTestSettingsState => ({
  ...state,

  parametersRemoveConfigModal: payload,
});

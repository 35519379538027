const isInsideRun = () => window.runUIhistoryContainer && window.STRESS_TEST_UI;

const defaultURLs = {
  stressTest: 'risk-api-stage.exante.eu',
  authDB: 'authdb-stage.exante.eu',
  nodeBack: 'node-back-stage.exante.eu',
};

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] STRESS_TEST application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseStressTestUrl = (fallback = defaultURLs.stressTest) => {
  if (isInsideRun()) {
    const url = window.STRESS_TEST_UI?.api_urls?.RISK_API;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseStressTestUrl',
          'window.STRESS_TEST_UI.api_urls.RISK_API',
          'process.env.REACT_APP_STRESS_TEST_API',
        ),
      );
      return process.env.REACT_APP_STRESS_TEST_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_STRESS_TEST_API || fallback;
};

export const getBaseAuthDBUrl = (fallback = defaultURLs.authDB) => {
  if (isInsideRun()) {
    const url = window.STRESS_TEST_UI?.api_urls?.AUTHDB;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseAuthDBUrl',
          'window.STRESS_TEST_UI.api_urls.AUTHDB',
          'process.env.REACT_APP_AUTHDB_API',
        ),
      );
      return process.env.REACT_APP_AUTHDB_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_AUTHDB_API || fallback;
};

export const getBaseNodeBackUrl = (fallback = defaultURLs.nodeBack) => {
  if (isInsideRun()) {
    const url = window.STRESS_TEST_UI?.api_urls?.NODE_BACK;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseNodeBackUrl',
          'window.STRESS_TEST_UI.api_urls.NODE_BACK',
          'process.env.REACT_APP_NODE_BACK_API',
        ),
      );
      return process.env.REACT_APP_NODE_BACK_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_NODE_BACK_API || fallback;
};

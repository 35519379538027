import dayjs from 'dayjs';

import { DATE_FORMAT_D_M_Y } from 'constants/common';
import { DEFAULT_TRADE_VALUE } from 'constants/manualTest';
import type { TManualTest, TManualTestServer } from 'types';

export const transformData = (
  tableData: TManualTest[],
): TManualTestServer[] => {
  return tableData.map((item) => {
    const trades = item.subRows?.reduce((acc, trade) => {
      if (!trade.tradeName || trade.tradeName === DEFAULT_TRADE_VALUE) {
        return acc;
      }

      return {
        ...acc,
        [trade.tradeName]: trade.quantity,
      };
    }, {});

    const manualTestDate =
      item.date || dayjs(new Date()).format(DATE_FORMAT_D_M_Y);

    return {
      account: item.name || '',
      date: manualTestDate,
      trades,
    };
  });
};

export const parseDate = (dateStr: string): string => {
  const patternDDMMYYYY = /(\d{2})\.(\d{2})\.(\d{4})/; // 20.01.2024

  return dateStr.replace(patternDDMMYYYY, '$3-$2-$1'); // 2024-01-20
};

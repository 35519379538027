import type { TQuantityTradeProps } from 'types';

import type { StressTestJobsState } from '../types';

export const onSetExistingQuantityValue = (
  state: StressTestJobsState,
  payload: TQuantityTradeProps,
): StressTestJobsState => {
  return {
    ...state,
    existingQuantityValue: payload,
  };
};

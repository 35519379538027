import { useContext, useMemo } from 'react';
import { IconButton, Modal } from 'react-ui-kit-exante';

import { prepareTableId } from 'utils';

import { StressTestSettingsContext } from '../context';

import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { useDeleteExcludeModal, useExclude } from './hooks';
import { TableContainer } from './styled';

const tableId = prepareTableId('parameters');

export const Exclude = () => {
  const { state, dispatch } = useContext(StressTestSettingsContext);

  const {
    columns,
    data,
    filteringProps,
    handleAddStressTestExclude,
    handleDeleteExclude,
    handleUpdateExclude,
    isLoading,
    refetch,
    serverPaginationProps,
  } = useExclude({ dispatch, tableId });

  const { handleDeleteConfirm, handleModalClose } = useDeleteExcludeModal({
    dispatch,
    refetch,
    state,
  });

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleUpdateExclude,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDeleteExclude,
        },
      ],
    }),
    [handleUpdateExclude, handleDeleteExclude],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddStressTestExclude',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Exclude"
            onClick={handleAddStressTestExclude}
          />
        ),
      },
    ],
    [handleAddStressTestExclude, isLoading, refetch],
  );

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={data?.results || []}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        filteringProps={filteringProps}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        rowActions={rowActions}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        tableId={tableId}
      />

      <Modal
        isOpened={state.removeExcludeModal.show}
        onClose={handleModalClose}
        title="Delete Stress Test Exclude"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleDeleteConfirm,
        }}
      >
        <div>Are you sure you want to delete the stress test exclude?</div>
      </Modal>
    </>
  );
};

import { useCallback, useContext } from 'react';
import { IconButton, Notification, Tooltip } from 'react-ui-kit-exante';

import {
  parseDate,
  transformData,
} from 'pages/StressTestJobs/ManualTest/helpers';
import { StressTestJobsContext } from 'pages/StressTestJobs/context';
import { jobService } from 'resources';
import type { TCreateJobMultipleAccountsPayload } from 'types';
import { sendUiKitErrorNotification } from 'utils';

type TRunJobProps = {
  accountName: string;
  date: string;
};

export const RunJob = ({ accountName, date }: TRunJobProps) => {
  const { state } = useContext(StressTestJobsContext);

  const handleRunJob = useCallback(async () => {
    try {
      if (accountName) {
        const payload = transformData(state.manualTestData)
          .filter((item) => {
            return item.account === accountName && item.date === date;
          })
          .map((item) => {
            return {
              account: item.account,
              date: parseDate(item.date),
              v_trades: item.trades || {},
            };
          }) as TCreateJobMultipleAccountsPayload[];

        await jobService.createJobMultipleAccounts(payload);
      } else if (!accountName) {
        await jobService.createJobByDate({
          date: parseDate(date),
        });
      }

      Notification.success({ title: 'Job successfully started' });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }, [state.manualTestData]);

  return (
    <Tooltip title="Run Job">
      <IconButton
        iconColor="action"
        iconName="PlayIcon"
        iconSize={20}
        onClick={handleRunJob}
      />
    </Tooltip>
  );
};

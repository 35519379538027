import { useMemo } from 'react';
import { Button, Loader } from 'react-ui-kit-exante';

import { ArrowLeftIcon } from 'images';
import { ReactComponent as DownloadingIcon } from 'images/svg/Downloading.svg';

import { LIMIT } from '../constants';
import { FetchMoreButton } from '../styled';

import { useClient } from './hooks';
import { Container, NoDataContainer, TableContainer } from './styled';

export const Client = () => {
  const {
    columns,
    handleBackToJobState,
    handleFetchMore,
    incorrectQueryParams,
    loading,
    skip,
    tableData,
  } = useClient();

  const additionalActions = useMemo(
    () => [
      {
        key: 'BackToJobsState',
        component: (
          <Button
            color="primary"
            onClick={handleBackToJobState}
            startIcon={<ArrowLeftIcon />}
          >
            Back to Jobs State
          </Button>
        ),
      },
    ],
    [handleBackToJobState],
  );

  if (incorrectQueryParams) {
    return (
      <NoDataContainer>
        <div>No Job ID or Group By is not account_id</div>
        <div>
          <Button
            color="primary"
            onClick={handleBackToJobState}
            startIcon={<ArrowLeftIcon />}
          >
            Go to Jobs State
          </Button>
        </div>
      </NoDataContainer>
    );
  }

  return (
    <Container>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={tableData.results}
        defaultSortBy={[]}
        expanded={{
          listOfInitialExpandedRowKeys: false,
        }}
        isFlexLayout
        isLoading={loading}
        tableId="ClientTable"
      />

      {loading && (
        <FetchMoreButton type="button">
          <Loader />
          <span>Load More</span>
        </FetchMoreButton>
      )}

      {!loading && tableData.count > skip + LIMIT && (
        <FetchMoreButton type="button" onClick={() => handleFetchMore()}>
          <DownloadingIcon />
          <span>Load More</span>
        </FetchMoreButton>
      )}
    </Container>
  );
};

import type {
  TParams,
  TPeriodicJobCreatePayload,
  TPeriodicJobServer,
  TPeriodicJobServerState,
  TPeriodicJobUpdate,
} from 'types';
import { apiRequest } from 'utils';

import { PERIODIC_JOBS_API, getPeriodicJobById } from './endpoints';

export class PeriodicJobRepository {
  public static fetchPeriodicJobList(params: TParams) {
    return apiRequest<TPeriodicJobServerState>({
      params,
      url: `${PERIODIC_JOBS_API}list/`,
    });
  }

  public static createPeriodicJob(payload: TPeriodicJobCreatePayload) {
    return apiRequest<TPeriodicJobServer>({
      data: payload,
      method: 'POST',
      url: PERIODIC_JOBS_API,
    });
  }

  public static updatePeriodicJob(id: string, payload: TPeriodicJobUpdate) {
    return apiRequest<TPeriodicJobServerState>({
      data: payload,
      method: 'PUT',
      url: getPeriodicJobById(id),
    });
  }

  public static deletePeriodicJob(id: string) {
    return apiRequest({
      url: getPeriodicJobById(id),
      method: 'DELETE',
    });
  }
}

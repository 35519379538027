import { DAYS_OF_WEEK } from 'constants/periodicJob';

export const normalizeDaysOfWeek = (runOnDays: string[]) => {
  return runOnDays
    .map((dayOfWeek) => {
      const weekDaysFromZero = DAYS_OF_WEEK.findIndex(
        (weekDay: string) => weekDay === dayOfWeek,
      );

      return weekDaysFromZero + 1;
    })
    .join(',');
};

export const getStartTime = (startTime: string, index: number) => {
  return startTime.split(':')[index];
};

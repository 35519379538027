import type { StressTestSettingsState, TRemoveExclude } from '../types';

export const onToggleRemoveExcludeModal = (
  state: StressTestSettingsState,
  payload: TRemoveExclude,
): StressTestSettingsState => ({
  ...state,

  removeExcludeModal: payload,
});

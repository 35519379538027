import { useCallback, useContext } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';

type TRemoveAccountProps = {
  accountName: string;
  date: string;
};

export const RemoveAccount = ({ accountName, date }: TRemoveAccountProps) => {
  const { dispatch } = useContext(StressTestJobsContext);

  const handleRemoveAccount = useCallback(() => {
    dispatch({
      type: StressTestJobsActions.ManualTestRemoveAccountModal,
      payload: {
        accountName,
        date,
        show: true,
      },
    });
  }, []);

  return (
    <Tooltip title="Remove account with positions">
      <IconButton
        iconColor="radical"
        iconName="DeleteIcon"
        iconSize={20}
        onClick={handleRemoveAccount}
      />
    </Tooltip>
  );
};

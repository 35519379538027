import { Link, useLocation } from 'react-router-dom';

import { PAGES } from 'constants/pages';
import { ROUTES } from 'constants/routes';

import { BrandContainer, Container, LinkStyled } from './styled';

export const Header = () => {
  const { pathname } = useLocation();
  const isInsideRun = !!window.runUIhistoryContainer;
  const pageName = pathname.split('/')[2];

  return !isInsideRun ? (
    <Container>
      <BrandContainer>
        <Link to={ROUTES.Main}>{PAGES.stressTestUI}</Link>
        <div>{`version ${process?.env?.REACT_APP_VERSION}`}</div>
      </BrandContainer>
      <div>
        <LinkStyled
          to={ROUTES.STRESS_TEST_JOBS}
          pageName={pageName === ROUTES.STRESS_TEST_JOBS.split('/')[2]}
        >
          {PAGES.stressTestJobs}
        </LinkStyled>
        <LinkStyled
          to={ROUTES.STRESS_TEST_RESULT}
          pageName={pageName === ROUTES.STRESS_TEST_RESULT.split('/')[2]}
        >
          {PAGES.stressTestResult}
        </LinkStyled>
        <LinkStyled
          to={ROUTES.STRESS_TEST_SETTINGS}
          pageName={pageName === ROUTES.STRESS_TEST_SETTINGS.split('/')[2]}
        >
          {PAGES.stressTestSettings}
        </LinkStyled>
      </div>
    </Container>
  ) : null;
};

import cn from 'classnames';
import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import SelectArrowIcon from '../../../Icons/components/ArrowDown';
import { Skeleton } from '../../Skeleton';

import { IconWrapper, Input, MenuItem } from './styled';
import { IUISelectProps } from './types';

export const Select: FC<IUISelectProps> = ({
  options,
  menuItemProps,
  menuProps,
  showSkeleton = false,
  className,
  ...rest
}) => {
  const { size = 'medium', fullWidth } = rest;

  return (
    <DefaultThemeProvider>
      {showSkeleton ? (
        <Skeleton
          height={size === 'small' ? 32 : 48}
          width={fullWidth ? '100%' : 200}
          className={cn('SelectSkeleton', className)}
        />
      ) : (
        <Input
          data-test-id="select_input"
          select
          SelectProps={{
            IconComponent: SelectArrowIcon,
            MenuProps: {
              sx: {
                '& .MuiList-root': {
                  padding: 0,
                },
              },
              ...menuProps,
            },
          }}
          className={cn('SelectComponent', className)}
          {...rest}
        >
          {options.map(({ value, label, icon, disabled }) => (
            <MenuItem
              disableRipple
              key={value}
              size={size}
              value={value}
              disabled={disabled}
              {...menuItemProps}
            >
              {Boolean(icon) && <IconWrapper>{icon}</IconWrapper>}
              {label}
            </MenuItem>
          ))}
        </Input>
      )}
    </DefaultThemeProvider>
  );
};

import type { TQuantityTradeProps } from 'types';

import type { StressTestJobsState } from '../types';

export const onSetVirtualPositionValue = (
  state: StressTestJobsState,
  payload: TQuantityTradeProps,
): StressTestJobsState => {
  return {
    ...state,
    virtualPositionValue: payload,
  };
};

export const ArrowLeftIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      height={18}
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

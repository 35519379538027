import { styled } from 'theme';

import { blockNonNativeProps } from '../../helpers';

import { TNoDataProps } from './NoData.types';

export const NoDataStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps('height'),
})<TNoDataProps>(({ theme, height }) => ({
  fontSize: '24px',
  fontFamily: theme.font.main,
  lineHeight: '32px',
  color: theme?.color.typo.primary,
  backgroundColor: theme?.color.table.bg.basic2,
  padding: '64px 24px',
  textAlign: 'center',
  height,
})) as React.ComponentType<TNoDataProps>;

import type { TSymbolIdInfoParams, TSymbolIdInfoResponse } from 'types';
import { apiRequest, type TOptionsArguments } from 'utils/apiRequest';

import { FETCH_SYMBOLID_INFO_ENDPOINT } from './endpoints';

export class SymbolRepository {
  public static fetchSymbolIdInfo(
    params: TSymbolIdInfoParams,
    options?: TOptionsArguments,
  ) {
    return apiRequest<TSymbolIdInfoResponse>(
      {
        url: FETCH_SYMBOLID_INFO_ENDPOINT,
        params,
      },
      options,
    );
  }
}

import { useCallback, useContext } from 'react';
import { Modal, Notification } from 'react-ui-kit-exante';

import { transformData } from 'pages/StressTestJobs/ManualTest/helpers';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';

type TRemoveTradeModalProps = {
  getLaunchParams: () => Promise<void>;
};

export const RemoveTradeModal = ({
  getLaunchParams,
}: TRemoveTradeModalProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const {
    manualTestRemoveTradeModal: { accountName, date, show, tradeName },
  } = state;

  const handleModalClose = () => {
    dispatch({
      type: StressTestJobsActions.ManualTestRemoveTradeModal,
      payload: {
        accountName: '',
        date: '',
        show: false,
        tradeName: '',
      },
    });
  };

  const handleDeleteConfirm = useCallback(async () => {
    dispatch({
      type: StressTestJobsActions.ManualTestRemoveTradeModal,
      payload: {
        accountName: '',
        date: '',
        show: false,
        tradeName: '',
      },
    });

    const transformedPayload = transformData(state.manualTestData).map(
      (item) => {
        const updatedItem = item;

        if (
          item.account === accountName &&
          item.date === date &&
          updatedItem.trades?.[tradeName]
        ) {
          delete updatedItem.trades?.[tradeName];
        }

        return updatedItem;
      },
    );

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    Notification.success({
      title: 'Successfully removed position',
    });
  }, [state.manualTestData]);

  return (
    <Modal
      isOpened={show}
      onClose={handleModalClose}
      title="Remove position"
      confirmButton={{
        confirmButtonName: 'Confirm',
        handleConfirm: handleDeleteConfirm,
      }}
    >
      <div>
        Are you sure you want to delete position {tradeName} of account{' '}
        {accountName} on date {date}?
      </div>
    </Modal>
  );
};

import {
  getBaseAuthDBUrl,
  getBaseNodeBackUrl,
  getBaseStressTestUrl,
} from './getBaseUrl';

export const getAPIHost = () => `https://${getBaseStressTestUrl()}`;

export const getAuthDBHost = () => `https://${getBaseAuthDBUrl()}`;

export const getNodeBackHost = () => `https://${getBaseNodeBackUrl()}`;

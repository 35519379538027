import { AxiosResponse } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import type {
  TParams,
  TSegregatedAccountPayload,
  TSegregatedAccountState,
} from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { SegregatedAccountRepository } from './segregatedAccount.repository';

export class SegregatedAccountService {
  public async fetchSegregatedAccountList(
    params: TParams,
  ): Promise<TSegregatedAccountState> {
    try {
      const { data } =
        await SegregatedAccountRepository.fetchSegregatedAccountList(params);

      return {
        count: data.count,
        results: data.results,
      };
    } catch (err) {
      sendUiKitErrorNotification(err);

      return {
        count: 0,
        results: [],
      };
    }
  }

  public async createSegregatedAccount(
    payload: TSegregatedAccountPayload,
  ): Promise<TSegregatedAccountState> {
    const { data } = await SegregatedAccountRepository.createSegregatedAccount(
      payload,
    );

    return data;
  }

  public async updateSegregatedAccount(
    id: string,
    payload: TSegregatedAccountPayload,
  ): Promise<void> {
    try {
      await SegregatedAccountRepository.updateSegregatedAccount(id, payload);

      Notification.success({
        title: 'Segregated Account successfully updated',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async deleteSegregatedAccount(id: string): Promise<AxiosResponse> {
    return SegregatedAccountRepository.deleteSegregatedAccount(id);
  }
}

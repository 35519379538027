import { isEmpty } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Input } from 'react-ui-kit-exante';

import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';

type TQuantityTradeProps = {
  accountName: string;
  date: string;
  quantity: number | null;
  tradeName: string;
};

export const ExistingQuantity = ({
  accountName,
  date,
  quantity,
  tradeName,
}: TQuantityTradeProps) => {
  const [currentValue, setCurrentValue] = useState<string | null>(
    String(quantity),
  );

  const {
    state: { existingQuantityValue },
    dispatch,
  } = useContext(StressTestJobsContext);

  const handleChangeExisting = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;

      setCurrentValue(value);

      dispatch({
        type: StressTestJobsActions.SetExistingQuantity,
        payload: {
          accountName,
          date,
          tradeName,
          quantity: Number(value),
        },
      });
    },
    [],
  );

  useEffect(() => {
    const isNotEqual =
      accountName !== existingQuantityValue.accountName ||
      date !== existingQuantityValue.date ||
      tradeName !== existingQuantityValue.tradeName;

    if (!isEmpty(existingQuantityValue) && isNotEqual) {
      setCurrentValue(String(quantity));
    }
  }, [existingQuantityValue]);

  if (!quantity) {
    return null;
  }

  return (
    <Input
      onChange={handleChangeExisting}
      placeholder="Quantity"
      size="small"
      type="text"
      value={currentValue}
    />
  );
};

import { ChangeEvent, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { accountTotalService, underlyingSummaryService } from 'resources';

import { CommentContainer, InputContainer } from './styled';

type CommentCellProps = {
  comment?: string;
  id?: string;
  type: string;
};

export const CommentCell = ({ comment, id, type }: CommentCellProps) => {
  const [initialComment, setInitialComment] = useState<string>(comment || '');
  const [currentComment, setCurrentComment] = useState<string>(comment || '');

  const handleChangeComment = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setCurrentComment(value);
  };

  const handleSaveComment = async () => {
    if (!id) {
      return;
    }

    setInitialComment(currentComment);

    if (type === 'summary') {
      await underlyingSummaryService.updateUnderlyingSummary(id, {
        comment: currentComment,
      });

      return;
    }

    await accountTotalService.updateAccountTotal(id, {
      comment: currentComment,
    });
  };

  const handleDeleteComment = async () => {
    if (!id) {
      return;
    }

    setInitialComment('');
    setCurrentComment('');

    if (type === 'summary') {
      await underlyingSummaryService.updateUnderlyingSummary(
        id,
        {
          comment: '',
        },
        'Comment successfully deleted',
      );

      return;
    }

    await accountTotalService.updateAccountTotal(
      id,
      {
        comment: '',
      },
      'Comment successfully deleted',
    );
  };

  if (!id) {
    return null;
  }

  return (
    <CommentContainer>
      <InputContainer
        onChange={handleChangeComment}
        type="text"
        value={currentComment}
      />

      <Tooltip title="Save Comment">
        <IconButton
          disabled={currentComment === initialComment}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={20}
          onClick={handleSaveComment}
        />
      </Tooltip>
      <Tooltip title="Delete Comment">
        <IconButton
          disabled={!initialComment}
          iconColor="radical"
          iconName="CloseIcon"
          iconSize={20}
          onClick={handleDeleteComment}
        />
      </Tooltip>
    </CommentContainer>
  );
};

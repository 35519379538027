import { AxiosResponse } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import type {
  TParametersServerResponse,
  TParametersPayload,
  TParams,
} from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { transformData } from './helpers';
import { ParametersConfigRepository } from './parametersConfig.repository';

export class ParametersConfigService {
  public async fetchConfigList(
    params: TParams,
  ): Promise<TParametersServerResponse> {
    try {
      const { data } = await ParametersConfigRepository.fetchConfigList(params);

      return transformData(data);
    } catch (err) {
      return {
        count: 0,
        results: [],
      };
    }
  }

  public async createConfig(
    payload: TParametersPayload,
  ): Promise<TParametersServerResponse> {
    const { data } = await ParametersConfigRepository.createConfig(payload);

    return data;
  }

  public async updateConfig(
    id: string,
    payload: TParametersPayload,
  ): Promise<void> {
    try {
      await ParametersConfigRepository.updateConfig(id, payload);

      Notification.success({
        title: 'Stress Test Config Parameter successfully updated',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async deleteConfig(id: string): Promise<AxiosResponse> {
    return ParametersConfigRepository.deleteConfig(id);
  }
}

import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, Panel, Select } from 'react-ui-kit-exante';

import { EXCLUSION_TYPE } from '../constants';

import { CONTROL_CLS } from './constants';
import { useExcludeAdd } from './hooks';
import { ActionsContainer, ControlsContainer } from './styled';

export const ExcludeAdd = () => {
  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  } = useExcludeAdd();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  const exclusionTypeOptions = useMemo(() => {
    return EXCLUSION_TYPE.map((type: string) => {
      return {
        value: type,
        label: type,
      };
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel title="Add Stress Parameter" action={renderActions()} />

      <ControlsContainer>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              onChange={field.onChange}
              options={exclusionTypeOptions}
              placeholder="Exclusion Type"
              sx={CONTROL_CLS}
              value={field.value}
              warning={dirtyFields.type}
              error={Boolean(errors.type)}
              message={errors.type?.message}
            />
          )}
        />
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Value"
              sx={CONTROL_CLS}
              warning={dirtyFields.value}
              error={Boolean(errors.value)}
              message={errors.value?.message}
            />
          )}
        />
      </ControlsContainer>
    </form>
  );
};

import { ButtonHTMLAttributes } from 'react';

import { styled, ThemeColorIcon } from 'theme';

import { blockNonNativeProps } from '../../../helpers';

type ActionButtonProps = {
  iconColor?: keyof ThemeColorIcon;
  active?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const styledProps = ['iconColor', 'active'];

export const ActionButtonStyled = styled('button', {
  shouldForwardProp: blockNonNativeProps(styledProps),
})<ActionButtonProps>(({ theme, iconColor, active }) => ({
  minWidth: 32,
  height: 32,
  color: active
    ? theme?.color.icon.primary
    : theme?.color.icon[iconColor || 'secondary'],
  display: 'flex',
  border: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: 0,
  '&:hover': {
    color: theme?.color.icon.promo,
  },
  '&:disabled': {
    color: theme?.color.icon.ghost,
    cursor: 'initial',
  },
  '& svg': {
    width: 24,
    height: 24,
  },
  '& .MuiBadge-badge': {
    backgroundColor: active
      ? theme?.color.icon.ghost
      : theme?.color.icon[iconColor || 'secondary'],
    color: theme?.color.icon.inverse,
    '&:hover': {
      backgroundColor: theme?.color.icon.promo,
    },
  },
})) as React.ComponentType<ActionButtonProps>;

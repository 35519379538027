export const PAGES = {
  excludeFromStress: 'Exclude from Stress',
  jobsManualTest: 'Manual Test',
  jobsStressJobsState: 'Stress Jobs State',
  resultVarPerFactor: 'VaR Per Factor',
  resultVarPerClient: 'VaR Per Client',
  runtimeSettings: 'Runtime Settings',
  segregatedClients: 'Segregated Clients',
  stressParameters: 'Stress Parameters',
  stressTestJobs: 'Stress Test Jobs',
  stressTestResult: 'Stress Test Result',
  stressTestSettings: 'Stress Test Settings',
  stressTestUI: 'Stress Test UI',
};

import qs from 'qs';

import {
  AUTHORIZATION_HEADER_KEY,
  SESSION_ID_HEADER_KEY,
} from 'constants/session';

import { removeEmptyStrings } from '../removeEmptyStrings';

import { TAccessToken } from './types';

export function getDefaultHeaders(sessionId = '', accessToken = '') {
  const baseHeaders = {
    'Content-Type': 'application/json',
  };

  if (sessionId) {
    return { ...baseHeaders, [SESSION_ID_HEADER_KEY]: sessionId };
  }

  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);

      return {
        ...baseHeaders,
        [AUTHORIZATION_HEADER_KEY]: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}

export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = removeEmptyStrings(params);

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}

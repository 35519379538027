import type {
  TParametersServerResponse,
  TParametersPayload,
  TParams,
} from 'types';
import { apiRequest } from 'utils';

import { PARAMETERS_CONFIG_API, getParametersConfigById } from './endpoints';

export class ParametersConfigRepository {
  public static fetchConfigList(params: TParams) {
    return apiRequest<TParametersServerResponse>({
      params,
      url: `${PARAMETERS_CONFIG_API}list/`,
    });
  }

  public static createConfig(payload: TParametersPayload) {
    return apiRequest<TParametersServerResponse>({
      data: payload,
      method: 'POST',
      url: PARAMETERS_CONFIG_API,
    });
  }

  public static updateConfig(id: string, payload: TParametersPayload) {
    return apiRequest<TParametersServerResponse>({
      data: payload,
      method: 'PUT',
      url: getParametersConfigById(id),
    });
  }

  public static deleteConfig(id: string) {
    return apiRequest({
      url: getParametersConfigById(id),
      method: 'DELETE',
    });
  }
}

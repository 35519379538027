import { createContext, Dispatch } from 'react';

import { initialState } from './initialState';
import { StressTestJobsActionsType, StressTestJobsState } from './types';

export const StressTestJobsContext = createContext<{
  state: StressTestJobsState;
  dispatch: Dispatch<StressTestJobsActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});

import type {
  TParams,
  TSegregatedAccountPayload,
  TSegregatedAccountState,
} from 'types';
import { apiRequest } from 'utils';

import { SEGREGATED_ACCOUNT_API, getSegregatedAccountById } from './endpoints';

export class SegregatedAccountRepository {
  public static fetchSegregatedAccountList(params: TParams) {
    return apiRequest<TSegregatedAccountState>({
      params,
      url: `${SEGREGATED_ACCOUNT_API}list/`,
    });
  }

  public static createSegregatedAccount(payload: TSegregatedAccountPayload) {
    return apiRequest<TSegregatedAccountState>({
      data: payload,
      method: 'POST',
      url: SEGREGATED_ACCOUNT_API,
    });
  }

  public static updateSegregatedAccount(
    id: string,
    payload: TSegregatedAccountPayload,
  ) {
    return apiRequest<TSegregatedAccountState>({
      data: payload,
      method: 'PUT',
      url: getSegregatedAccountById(id),
    });
  }

  public static deleteSegregatedAccount(id: string) {
    return apiRequest({
      url: getSegregatedAccountById(id),
      method: 'DELETE',
    });
  }
}

import { isBoolean, isNaN, isNumber, isString } from 'lodash';
import { getBrowserLocale } from 'react-ui-kit-exante';

export const formatNumberToFinancial = (
  value: unknown,
  options?: Intl.NumberFormatOptions,
) => {
  const currentLocale = getBrowserLocale();
  const isValidNumber = isNumber(value) && !isNaN(value);
  const isValidNumericString = isString(value) && !isNaN(Number(value));

  const customOptions = {
    ...options,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (isValidNumber) {
    return value.toLocaleString(currentLocale, customOptions);
  }

  if (isValidNumericString) {
    return Number(value).toLocaleString(currentLocale, customOptions);
  }

  if (isNaN(value) || (!isBoolean(value) && !value)) {
    return '0';
  }

  return isString(value) ? value : JSON.stringify(value);
};

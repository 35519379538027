import type {
  StressTestSettingsState,
  TRemoveSegregatedAccount,
} from '../types';

export const onToggleRemoveSegregatedAccountModal = (
  state: StressTestSettingsState,
  payload: TRemoveSegregatedAccount,
): StressTestSettingsState => ({
  ...state,

  removeSegregatedAccountModal: payload,
});

import { AxiosResponse } from 'axios';

import type {
  TCreateJobByDatePayload,
  TCreateJobMultipleAccountsPayload,
  TJobStateServer,
  TParams,
} from 'types';

import { transformData, transformParams } from './helpers';
import { JobRepository } from './job.repository';

export class JobService {
  public async fetchJobsList(params: TParams): Promise<TJobStateServer> {
    const { data } = await JobRepository.fetchJobsList(transformParams(params));

    return transformData(data);
  }

  public async createJobMultipleAccounts(
    payload: TCreateJobMultipleAccountsPayload[],
  ): Promise<void> {
    await JobRepository.createJobMultipleAccounts(payload);
  }

  public async createJobByDate(
    payload: TCreateJobByDatePayload,
  ): Promise<void> {
    await JobRepository.createJobByDate(payload);
  }

  public async deleteJob(id: string): Promise<AxiosResponse> {
    return JobRepository.deleteJob(id);
  }
}

import { useCallback, useContext } from 'react';
import { Modal, Notification } from 'react-ui-kit-exante';

import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';

import { transformData } from '../helpers';

type TRemoveAccountModalProps = {
  getLaunchParams: () => Promise<void>;
};

export const RemoveAccountModal = ({
  getLaunchParams,
}: TRemoveAccountModalProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const {
    manualTestRemoveAccountModal: { accountName, date, show },
  } = state;

  const handleModalClose = () => {
    dispatch({
      type: StressTestJobsActions.ManualTestRemoveAccountModal,
      payload: {
        accountName: '',
        date: '',
        show: false,
      },
    });
  };

  const handleDeleteConfirm = useCallback(async () => {
    dispatch({
      type: StressTestJobsActions.ManualTestRemoveAccountModal,
      payload: {
        accountName: '',
        date: '',
        show: false,
      },
    });

    const transformedPayload = transformData(state.manualTestData).filter(
      (item) => {
        if (!item.account && !accountName && item.date === date) {
          return false;
        }

        return !(item.account === accountName && item.date === date);
      },
    );

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    Notification.success({
      title: 'Successfully removed account',
    });
  }, [state.manualTestData]);

  return (
    <Modal
      isOpened={show}
      onClose={handleModalClose}
      title="Remove Account"
      confirmButton={{
        confirmButtonName: 'Confirm',
        handleConfirm: handleDeleteConfirm,
      }}
    >
      <div>
        Are you sure you want to delete account {accountName} with date {date}?
      </div>
    </Modal>
  );
};

import { DEFAULT_TRADE_VALUE } from 'constants/manualTest';
import type { TManualTest, TManualTestServer } from 'types';

export const normalizeData = (data: TManualTestServer[]): TManualTest[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((item) => {
    const subRows = Object.keys(item.trades || {}).map((key) => ({
      accountName: item.account,
      date: item.date,
      name: key,
      quantity: item.trades?.[key] || null,
      tradeName: key,
    }));

    if (item.account) {
      subRows.unshift({
        accountName: item.account,
        date: item.date,
        name: DEFAULT_TRADE_VALUE,
        quantity: null,
        tradeName: DEFAULT_TRADE_VALUE,
      });
    }

    return {
      date: item.date,
      name: item.account,
      quantity: null,
      subRows,
    };
  });
};

import { styled } from 'theme';

import { DEFAULT_SIZE } from './constants';
import { TLoaderContainerProps } from './types';

/**
 * Sizing container for the {@link Loader}
 */
export const LoaderContainer = styled('div')<TLoaderContainerProps>(
  ({ theme, size = DEFAULT_SIZE }) => {
    const sizePx = size === 'm' ? theme.size?.loader.md : theme.size?.loader.lg;

    return {
      width: typeof size === 'number' ? size : sizePx,
      height: typeof size === 'number' ? size : sizePx,
      lineHeight: typeof size === 'number' ? size : sizePx,
    };
  },
);

export type TLoaderWrapperProps = {
  /** If `true`, the content is centered */
  isCentered: boolean;
  /**
   * Works with {@link isCentered}
   *
   * If `true`, the content is centered relative to the full size of its parent
   * Otherwise, the content is centered inside the wrapper
   */
  isAbsolute: boolean;
};

/**
 * Centering wrapper for the {@link Loader}
 */
export const LoaderWrapper = styled('div')<TLoaderWrapperProps>(
  ({ isAbsolute, isCentered }) => {
    if (!isCentered) {
      return {};
    }

    return isAbsolute
      ? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }
      : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
  },
);

import { useContext, useMemo } from 'react';
import { IconButton, Modal } from 'react-ui-kit-exante';

import { prepareTableId } from 'utils';

import { StressTestSettingsContext } from '../context';

import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { useDeleteRuntimeSettingModal, useRuntimeSettings } from './hooks';
import { TableContainer } from './styled';

const tableId = prepareTableId('runtimeSettings');

export const RuntimeSettings = () => {
  const { state, dispatch } = useContext(StressTestSettingsContext);

  const {
    columns,
    data,
    isLoading,
    handleAddRuntimeSetting,
    handleDeleteSetting,
    handleUpdateSetting,
    refetch,
    serverPaginationProps,
  } = useRuntimeSettings({ dispatch, state, tableId });

  const { handleDeleteConfirm, handleModalClose } =
    useDeleteRuntimeSettingModal({
      dispatch,
      refetch,
      state,
    });

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleUpdateSetting,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDeleteSetting,
        },
      ],
    }),
    [handleUpdateSetting, handleDeleteSetting],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddSetting',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Setting"
            onClick={handleAddRuntimeSetting}
          />
        ),
      },
    ],
    [handleAddRuntimeSetting, isLoading, refetch],
  );

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={data?.results || []}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        rowActions={rowActions}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        tableId={tableId}
      />

      <Modal
        isOpened={state.runtimeSettingRemoveModal.show}
        onClose={handleModalClose}
        title="Delete Runtime Setting"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleDeleteConfirm,
        }}
      >
        <div>Are you sure you want to delete the runtime setting?</div>
      </Modal>
    </>
  );
};

import { Notification } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { periodicJobService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import type { TUseDeleteRuntimeSettingModalProps } from '../types';

export const useDeleteRuntimeSettingModal = ({
  dispatch,
  refetch,
  state,
}: TUseDeleteRuntimeSettingModalProps) => {
  const handleModalClose = () => {
    dispatch({
      type: StressTestSettingsActions.RuntimeSettingRemoveModal,
      payload: {
        id: '',
        show: false,
      },
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      if (state.runtimeSettingRemoveModal.id) {
        await periodicJobService.deletePeriodicJob(
          state.runtimeSettingRemoveModal.id,
        );

        Notification.success({
          title: `Runtime Setting successfully deleted with ID ${state.runtimeSettingRemoveModal.id}`,
        });

        refetch();
      }
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      handleModalClose();
    }
  };

  return {
    handleDeleteConfirm,
    handleModalClose,
  };
};

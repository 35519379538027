import { Tabs, styled } from 'react-ui-kit-exante';

export const TabsWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const TabsHeader = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  padding: '24px 24px 12px 24px',
}));

export const TabsHeaderTitle = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '32px',
  lineHeight: '48px',
  maxWidth: '960px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const TabsContainer = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
}));

export const FetchMoreButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: 'transparent',
  border: 0,
  color: theme.color.typo.action,
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  height: '24px',
  marginLeft: '18px',
  padding: 0,
  userSelect: 'none',

  '& svg': {
    fill: theme.color.icon.action,
    transform: 'scale(0.85)',
  },

  '& span': {
    fontSize: '14px',
  },

  '& .loader': {
    marginLeft: '4px',
    marginRight: '4px',
    transform: 'scale(1)',
  },

  '&:hover': {
    color: theme.color.typo.primary,
  },

  '&:hover svg': {
    fill: theme.color.icon.primary,
  },
}));

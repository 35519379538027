import { Notification } from 'react-ui-kit-exante';

import { StressTestJobsActions } from 'pages/StressTestJobs/context';
import { jobService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import type { TUseDeleteStateModalProps } from '../types';

export const useDeleteStateModal = ({
  dispatch,
  refetch,
  state,
}: TUseDeleteStateModalProps) => {
  const handleModalClose = () => {
    dispatch({
      type: StressTestJobsActions.StateRemoveJobModal,
      payload: {
        id: '',
        show: false,
      },
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      if (state.stateRemoveJobModal.id) {
        await jobService.deleteJob(state.stateRemoveJobModal.id);

        Notification.success({
          title: `Job successfully deleted with ID ${state.stateRemoveJobModal.id}`,
        });

        await refetch();
      }
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      handleModalClose();
    }
  };

  return {
    handleDeleteConfirm,
    handleModalClose,
  };
};

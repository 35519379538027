import type { TUnderlyingSummaryPayload } from 'types';
import { apiRequest } from 'utils';

import { getUnderlyingSummaryByIdAPI } from './endpoints';

export class UnderlyingSummaryRepository {
  public static updateUnderlyingSummary(
    underlyingID: string,
    payload: TUnderlyingSummaryPayload,
  ) {
    return apiRequest({
      data: payload,
      method: 'PATCH',
      url: getUnderlyingSummaryByIdAPI(underlyingID),
    });
  }
}

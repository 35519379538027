import { Notification } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { parametersConfigService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import type { TUseDeleteParametersModalProps } from '../types';

export const useDeleteParametersModal = ({
  dispatch,
  refetch,
  state,
}: TUseDeleteParametersModalProps) => {
  const handleModalClose = () => {
    dispatch({
      type: StressTestSettingsActions.ParametersRemoveConfigModal,
      payload: {
        id: '',
        show: false,
      },
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      if (state.parametersRemoveConfigModal.id) {
        await parametersConfigService.deleteConfig(
          state.parametersRemoveConfigModal.id,
        );

        Notification.success({
          title: `Stress Parameter Config successfully deleted with ID ${state.parametersRemoveConfigModal.id}`,
        });

        refetch();
      }
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      handleModalClose();
    }
  };

  return {
    handleDeleteConfirm,
    handleModalClose,
  };
};

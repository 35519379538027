export function addBaseTag() {
  const baseTag = document.createElement('base');
  const path = window.location.pathname.split('/');
  const branchIdx = path.findIndex((p) => /RB-\d*/.test(p));

  if (branchIdx !== -1) {
    baseTag.href = `${
      window.location.origin + path.slice(0, branchIdx + 1).join('/')
    }/`;

    document.head.prepend(baseTag);
  }
}

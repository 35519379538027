import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useQuery } from 'hooks';
import { JobsTabs } from 'pages/StressTestJobs';
import { LIMIT } from 'pages/StressTestResult/constants';
import { resultService } from 'resources';
import type { TFactorResult } from 'types';

import { getColumns } from '../columns';

export const useFactor = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [tableData, setTableData] = useState<TFactorResult>({
    count: 0,
    results: [],
  });
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const getFactor = useCallback(
    async (skipParam = 0) => {
      setLoading(true);

      const data = await resultService.fetchFactorList({
        job_id: query.get('jobId'),
        limit: LIMIT,
        skip: skipParam,
      });

      setLoading(false);
      setTableData({
        count: data.count,
        results: [...tableData.results, ...data.results],
      });
    },
    [loading, query, skip, tableData],
  );

  const columns = useMemo(() => getColumns(), []);

  const incorrectQueryParams = useMemo(() => {
    if (!query.get('jobId') || query.get('groupBy') !== 'underlying') {
      return true;
    }

    return false;
  }, [query]);

  const handleBackToJobState = useCallback(() => {
    navigate(ROUTES.STRESS_TEST_JOBS, {
      state: {
        activeTab: JobsTabs.State,
      },
    });
  }, [navigate]);

  const handleFetchMore = useCallback(() => {
    getFactor(skip + LIMIT);
    setSkip(skip + LIMIT);
  }, [getFactor, skip]);

  useEffect(() => {
    if (query.get('jobId') && query.get('groupBy') === 'underlying') {
      getFactor();
    }
  }, []);

  return {
    columns,
    handleBackToJobState,
    handleFetchMore,
    incorrectQueryParams,
    loading,
    skip,
    tableData,
  };
};

import type {
  TCreateJobByDatePayload,
  TCreateJobMultipleAccountsPayload,
  TJobStateServer,
  TParams,
} from 'types';
import { apiRequest } from 'utils';

import {
  JOBS_API,
  JOBS_LAUNCH_BY_DATE_API,
  JOBS_LAUNCH_MULTIPLE_ACCOUNTS_API,
  getJobByIdAPI,
} from './endpoints';

export class JobRepository {
  public static fetchJobsList(params: TParams) {
    return apiRequest<TJobStateServer>({
      params,
      url: `${JOBS_API}list/`,
    });
  }

  public static createJobMultipleAccounts(
    payload: TCreateJobMultipleAccountsPayload[],
  ) {
    return apiRequest({
      data: payload,
      method: 'POST',
      url: JOBS_LAUNCH_MULTIPLE_ACCOUNTS_API,
    });
  }

  public static createJobByDate(payload: TCreateJobByDatePayload) {
    return apiRequest({
      data: payload,
      method: 'POST',
      url: JOBS_LAUNCH_BY_DATE_API,
    });
  }

  public static deleteJob(id: string) {
    return apiRequest({
      url: getJobByIdAPI(id),
      method: 'DELETE',
    });
  }
}

import { IconButton, blockNonNativeProps, styled } from 'react-ui-kit-exante';

type StatusSpanProps = {
  status: 'action' | 'radical' | 'warning';
};

export const StatusContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}));

export const StatusSpan = styled('span', {
  shouldForwardProp: blockNonNativeProps(['status']),
})<StatusSpanProps>(({ status, theme }) => ({
  color: theme.color.typo?.[status],
}));

export const IconButtonContainer = styled(IconButton)(() => ({
  cursor: 'default !important',
  pointerEvents: 'none',
}));

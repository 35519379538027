import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import { TabPanelProps } from './types';

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => {
  return (
    <DefaultThemeProvider>
      <div hidden={value !== index} {...rest}>
        {value === index ? children : null}
      </div>
    </DefaultThemeProvider>
  );
};

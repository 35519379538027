import { Table, styled } from 'react-ui-kit-exante';

import type { TSegregatedAccount } from 'types';

export const TableContainer = styled(Table<TSegregatedAccount>)(() => ({
  '& > div': {
    overflowY: 'visible',
  },
  '& div[data-test-id="table__body--editable-cell"]': {
    overflow: 'visible',
  },
}));

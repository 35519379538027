import type { TParams, TExcludeServerResponse, TExcludePayload } from 'types';
import { apiRequest } from 'utils';

import { EXCLUDE_API, getExcludeById } from './endpoints';

export class ExcludeRepository {
  public static fetchExcludeList(params: TParams) {
    return apiRequest<TExcludeServerResponse>({
      params,
      url: `${EXCLUDE_API}list/`,
    });
  }

  public static createExclude(payload: TExcludePayload) {
    return apiRequest<TExcludeServerResponse>({
      data: payload,
      method: 'POST',
      url: EXCLUDE_API,
    });
  }

  public static updateExclude(id: string, payload: TExcludePayload) {
    return apiRequest<TExcludeServerResponse>({
      data: payload,
      method: 'PUT',
      url: getExcludeById(id),
    });
  }

  public static deleteExclude(id: string) {
    return apiRequest({
      url: getExcludeById(id),
      method: 'DELETE',
    });
  }
}

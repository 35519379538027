import { Panel, styled } from 'react-ui-kit-exante';

export const PanelContainer = styled(Panel)(() => ({
  paddingBottom: '0 !important',
}));

export const ControlsContainer = styled('div')(({ theme }) => ({
  background: theme.color.bg.primary,
  borderTop: `1px ${theme.color.dropdown.border} solid`,
  minHeight: '80vh',
  padding: '24px',
}));

export const ActionsContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));

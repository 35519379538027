import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { Input, Notification } from 'react-ui-kit-exante';

import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';

type TVirtualPositionQuantityProps = {
  accountName: string;
  date: string;
  quantity: number | null;
};

export const VirtualPositionQuantity = ({
  accountName,
  date,
  quantity,
}: TVirtualPositionQuantityProps) => {
  const [quantityValue, setQuantityValue] = useState<string | null>(
    typeof quantity === 'number' ? String(quantity) : quantity,
  );

  const {
    state: { virtualPositionValue },
    dispatch,
  } = useContext(StressTestJobsContext);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      const hasTradeName = Boolean(virtualPositionValue.tradeName);
      const isMinusSign = value.length === 1 && value.includes('-');

      setQuantityValue(value);

      if (!isMinusSign && Number.isNaN(Number(value))) {
        Notification.warning({ title: 'Quantity is not a valid number' });

        return;
      }

      if (!hasTradeName) {
        Notification.warning({ title: 'Select Virtual Position' });

        return;
      }

      dispatch({
        type: StressTestJobsActions.SetVirtualPosition,
        payload: {
          accountName,
          date,
          tradeName: virtualPositionValue.tradeName,
          quantity: Number(value),
        },
      });
    },
    [virtualPositionValue],
  );

  return (
    <Input
      onChange={handleChange}
      placeholder="Quantity"
      size="small"
      type="text"
      value={quantityValue || ''}
    />
  );
};

import type { TClientResult, TFactorResult, TParams } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { normalizeClient, normalizeFactor } from './helpers';
import { ResultRepository } from './result.repository';

export class ResultService {
  public async fetchFactorList(params: TParams): Promise<TFactorResult> {
    try {
      const { data } = await ResultRepository.fetchFactorList(params);

      return normalizeFactor(data);
    } catch (err) {
      sendUiKitErrorNotification(err);

      return {
        count: 0,
        results: [],
      };
    }
  }

  public async fetchClientList(params: TParams): Promise<TClientResult> {
    try {
      const { data } = await ResultRepository.fetchClientList(params);

      return normalizeClient(data);
    } catch (err) {
      sendUiKitErrorNotification(err);

      return {
        count: 0,
        results: [],
      };
    }
  }
}

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateCountOfPages, useTableData } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { StressTestSettingsRoutes } from 'pages/StressTestSettings/routes';
import { segregatedAccountService } from 'resources';
import type { TSegregatedAccount, TSegregatedAccountState } from 'types';

import { getColumns } from '../columns';
import { getDefaultPagination } from '../constants';
import type {
  TGetParametersProps,
  TUseSegregatedAccountsProps,
} from '../types';

export const useSegregatedAccounts = ({
  dispatch,
  tableId,
}: TUseSegregatedAccountsProps) => {
  const navigate = useNavigate();

  const getSegregatedAccountsList = useCallback(
    ({ params: { page, sorting, ...rest } }: TGetParametersProps) => {
      return segregatedAccountService.fetchSegregatedAccountList({
        ...rest,
      });
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getSegregatedAccountsList },
      pagination: {
        getDefaultPagination,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getSegregatedAccountsList, tableId],
  );

  const {
    data,
    fetchData: refetch,
    filters,
    isLoading,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
  } = useTableData<TSegregatedAccountState>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const filteringProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const handleUpdateSegregatedAccount = useCallback(
    async (_: TSegregatedAccount, updated: TSegregatedAccount) => {
      const { actions, id, ...payload } = updated;

      await segregatedAccountService.updateSegregatedAccount(id, payload);

      refetch();
    },
    [refetch],
  );

  const handleDeleteSegregatedAccount = useCallback(
    (rowValue: TSegregatedAccount) => {
      const { id } = rowValue;

      dispatch({
        type: StressTestSettingsActions.RemoveSegregatedAccountModal,
        payload: {
          id,
          show: true,
        },
      });
    },
    [],
  );

  const handleAddSegregatedAccount = useCallback(() => {
    navigate(StressTestSettingsRoutes.SEGREGATED_ACCOUNT_ADD);
  }, [navigate]);

  return {
    columns,
    data,
    filteringProps,
    handleAddSegregatedAccount,
    handleDeleteSegregatedAccount,
    handleUpdateSegregatedAccount,
    isLoading,
    refetch,
    serverPaginationProps,
  };
};

import type { TAccountTotalPayload } from 'types';
import { apiRequest } from 'utils';

import { getAccountTotalByIdAPI } from './endpoints';

export class AccountTotalRepository {
  public static updateAccountTotal(
    accountTotalID: string,
    payload: TAccountTotalPayload,
  ) {
    return apiRequest({
      data: payload,
      method: 'PATCH',
      url: getAccountTotalByIdAPI(accountTotalID),
    });
  }
}

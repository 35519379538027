import { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Modal, Table } from 'react-ui-kit-exante';

import { RefreshButton } from 'components';
import { DEFAULT_POLLING_INTERVAL_20 } from 'constants/common';
import { useInterval } from 'hooks';
import { prepareTableId } from 'utils';

import { StressTestJobsContext } from '../context';

import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { useDeleteStateModal, useStateComponentHook } from './hooks';

const tableId = prepareTableId('jobState');

export const State = () => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);

  const {
    columns,
    data,
    filteringProps,
    handleCellClick,
    handleClickOnRefresh,
    handleDeleteJob,
    isLoading,
    refetch,
    serverPaginationProps,
  } = useStateComponentHook({
    dispatch,
    setIsRefreshBtnClicked,
    state,
    tableId,
  });

  const { handleDeleteConfirm, handleModalClose } = useDeleteStateModal({
    dispatch,
    refetch,
    state,
  });

  const rowActions = useMemo(
    () => ({
      show: true,
      hideEdit: true,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={18}
            />
          ),
          title: 'Delete Job',
          onClick: (rowValue: unknown) => handleDeleteJob(rowValue),
        },
      ],
    }),
    [handleDeleteJob],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: (
          <RefreshButton loading={isLoading} onRefresh={handleClickOnRefresh} />
        ),
      },
    ],
    [isLoading, handleClickOnRefresh],
  );

  useInterval(() => {
    refetch();
  }, DEFAULT_POLLING_INTERVAL_20);

  useEffect(() => {
    if (isRefreshBtnClicked && !isLoading) {
      setIsRefreshBtnClicked(false);
    }
  }, [isLoading, isRefreshBtnClicked]);

  const isInitialLoading = !data && isLoading;

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={data?.results || []}
        defaultSortBy={DEFAULT_SORTING}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        disableSortBy
        filteringProps={filteringProps}
        handleCellClick={handleCellClick}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isInitialLoading || isRefreshBtnClicked}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        rowActions={rowActions}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        showScrollbar
        tableId={tableId}
      />

      <Modal
        isOpened={state.stateRemoveJobModal.show}
        onClose={handleModalClose}
        title="Delete Job"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleDeleteConfirm,
        }}
      >
        <div>Are you sure you want to delete the job?</div>
      </Modal>
    </>
  );
};

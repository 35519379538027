import type { TAccountDate } from 'types';

import type { StressTestJobsState } from '../types';

export const onSetDateValue = (
  state: StressTestJobsState,
  payload: TAccountDate,
): StressTestJobsState => {
  return {
    ...state,
    accountDateValue: payload,
  };
};

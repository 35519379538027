import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { CellProps } from 'react-table';

import { styled } from 'theme';

import { useTranslator } from '../../contexts/TranslationContext';
import { useModal } from '../../hooks';
import { Input } from '../Inputs/Input/Input';
import { Modal } from '../Modal';

export const StyledValue = styled('input')(() => ({
  padding: '0',
  margin: '0',
  border: '0',
  outline: 'none',
  backgroundColor: 'inherit',
  cursor: 'pointer',
  caretColor: 'transparent',
}));

export const EditableCell = ({
  value: initialValue,
  row,
  column,
  handleCellDataEdit,
  showConfirmationModal = {},
}: CellProps<any>) => {
  const { t } = useTranslator();
  const { index } = row;
  const { id } = column;
  const [value, setValue] = useState(initialValue);
  const [isEdit, setIsEdit] = useState(false);
  const modal = useModal();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    if (showConfirmationModal[id]) {
      modal.onOpen();
    } else {
      handleCellDataEdit(index, id, value, row, column);
      setIsEdit(false);
    }
  };

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    setIsEdit(true);
    event.stopPropagation();
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  };

  const handleSubmitConfirmationModal = () => {
    modal.onClose();
    setIsEdit(false);
    handleCellDataEdit(index, id, value, row, column);
  };

  const handleCancelConfirmationModal = () => {
    setValue(initialValue);
    modal.onClose();
    setIsEdit(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {isEdit && (
        <Input
          autoFocus
          value={value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          data-test-id="table__head--editable-cell"
          size="small"
          sx={{
            width: 'auto',
            '& .MuiInputBase-root': {
              padding: 0,
            },
          }}
        />
      )}
      {!isEdit && <StyledValue onClick={handleClick} defaultValue={value} />}
      <Modal
        isOpened={modal.isOpened}
        title={t('ui__table__cell__modal_confirm_edit__title')}
        confirmButton={{
          handleConfirm: handleSubmitConfirmationModal,
          confirmButtonName: t(
            'ui__table__cell__modal_confirm_edit__btn_confirm',
          ),
        }}
        cancelButton={{
          cancelButtonName: t(
            'ui__table__cell__modal_confirm_edit__btn_cancel',
          ),
        }}
        onClose={handleCancelConfirmationModal}
      >
        {t('ui__table__cell__modal_confirm_edit__text')}
      </Modal>
    </>
  );
};

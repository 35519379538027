import { ICellValue, IColumn } from 'react-ui-kit-exante';

import type { TClient } from 'types';
import { formatNumberToFinancial } from 'utils';

import { CommentCell } from '../CommentCell';

export const getColumns = (): IColumn<TClient> => [
  {
    Header: 'Client / Factor',
    id: 'clientFactor',
    accessor: 'clientFactor',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      const { factor, client } = original;

      if (factor) {
        return factor;
      }

      return client;
    },
  },
  {
    Header: 'Scenario Delta',
    id: 'scenarioDelta',
    accessor: 'scenarioDelta',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      if (typeof original.scenarioDelta !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioDelta);
    },
  },
  {
    Header: 'Scenario IV',
    id: 'scenarioIv',
    accessor: 'scenarioIv',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      if (typeof original.scenarioIv !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioIv);
    },
  },
  {
    Header: 'Scenario PNL',
    id: 'scenarioPnl',
    accessor: 'scenarioPnl',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      if (typeof original.scenarioPnl !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.scenarioPnl);
    },
  },
  {
    Header: 'Nav',
    id: 'nav',
    accessor: 'nav',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      if (typeof original.nav !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.nav);
    },
  },
  {
    Header: 'VaR',
    id: 'var',
    accessor: 'var',
    align: 'left',
    disableSortBy: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      if (typeof original.var !== 'number') {
        return null;
      }

      return formatNumberToFinancial(original.var);
    },
  },
  {
    Header: 'Scenario',
    id: 'scenario',
    accessor: 'scenario',
    Cell: ({ row: { original } }: ICellValue<TClient>) =>
      original.scenario || null,
  },
  {
    Header: 'Comment',
    id: 'comment',
    accessor: 'comment',
    disableSortBy: true,
    shouldExpand: false,
    Cell: ({ row: { original } }: ICellValue<TClient>) => {
      const { comment, accTotalId } = original;

      return <CommentCell comment={comment} id={accTotalId} type="" />;
    },
  },
];

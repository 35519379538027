import React from 'react';
import { createRoot } from 'react-dom/client';

import { initResources } from 'resources';
import { addBaseTag } from 'utils';

import { App } from './App';
import { ComposedThemeProvider } from './theme';

addBaseTag();
initResources();

const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('stress-test-ui');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  root.render(
    <React.StrictMode>
      <ComposedThemeProvider>
        <App />
      </ComposedThemeProvider>
    </React.StrictMode>,
  );
};

window.STRESS_TEST_UI = {
  ...(window.STRESS_TEST_UI || {}),
  render: (node: Element) => initApp(node),
};

// Rendered not inside Run UI
if (!window.runUIhistoryContainer) {
  initApp();
}

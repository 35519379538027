import { useCallback, useContext } from 'react';
import { IconButton, Notification, Tooltip } from 'react-ui-kit-exante';

import { transformData } from 'pages/StressTestJobs/ManualTest/helpers';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';

type TSaveDateProps = {
  accountName: string;
  date: string;
  getLaunchParams: () => Promise<void>;
};

export const SaveDate = ({
  accountName,
  date,
  getLaunchParams,
}: TSaveDateProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const {
    accountDateValue: {
      accountName: selectedAccount,
      date: selectedDate,
      initialDate,
    },
  } = state;

  const handleSaveDate = useCallback(async () => {
    const transformedData = transformData(state.manualTestData);

    /**
     * Validation for 2 identical accounts with the same dates
     * Validation for identical All Accounts with the same dates
     */
    const hasSameAccountDate = transformedData.some((item) => {
      if (
        (item.account === accountName || (!item.account && !accountName)) &&
        item.date === selectedDate
      ) {
        return true;
      }

      return false;
    });

    if (hasSameAccountDate) {
      let accountText = `account ${accountName}`;

      if (!accountName) {
        accountText = 'All Accounts';
      }

      Notification.warning({
        title: `There is identical ${accountText} with date ${selectedDate}`,
      });

      return;
    }

    const transformedPayload = transformedData.map((item) => {
      if (
        (item.account === accountName || (!item.account && !accountName)) &&
        item.date === date
      ) {
        return {
          ...item,
          date: selectedDate,
        };
      }

      return item;
    });

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    dispatch({
      type: StressTestJobsActions.RemoveDateValue,
    });

    Notification.success({ title: 'Successfully updated date' });
  }, [state.manualTestData, state.accountDateValue]);

  let disabled = true;

  if (initialDate === date) {
    if (selectedAccount === accountName && selectedDate !== date) {
      disabled = false;
    } else if (!selectedAccount && !accountName && selectedDate !== date) {
      disabled = false;
    }
  }

  return (
    <Tooltip title="Save date">
      <IconButton
        disabled={disabled}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={18}
        onClick={handleSaveDate}
      />
    </Tooltip>
  );
};

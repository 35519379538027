/* eslint-disable import/no-mutable-exports */

import { AccountTotalService } from './accountTotal';
import { ExcludeService } from './exclude';
import { JobService } from './job';
import { LaunchParamsService } from './launchParams';
import { ParametersConfigService } from './parametersConfig';
import { PeriodicJobService } from './periodicJob';
import { ResultService } from './result';
import { SegregatedAccountService } from './segregatedAccount';
import { SymbolService } from './symbol';
import { UnderlyingSummaryService } from './underlyingSummary';

let accountTotalService: AccountTotalService;
let excludeService: ExcludeService;
let jobService: JobService;
let launchParamsService: LaunchParamsService;
let parametersConfigService: ParametersConfigService;
let periodicJobService: PeriodicJobService;
let resultService: ResultService;
let segregatedAccountService: SegregatedAccountService;
let symbolService: SymbolService;
let underlyingSummaryService: UnderlyingSummaryService;

export function initResources() {
  accountTotalService = new AccountTotalService();
  excludeService = new ExcludeService();
  parametersConfigService = new ParametersConfigService();
  jobService = new JobService();
  launchParamsService = new LaunchParamsService();
  periodicJobService = new PeriodicJobService();
  resultService = new ResultService();
  segregatedAccountService = new SegregatedAccountService();
  symbolService = new SymbolService();
  underlyingSummaryService = new UnderlyingSummaryService();
}

export {
  accountTotalService,
  excludeService,
  parametersConfigService,
  jobService,
  launchParamsService,
  periodicJobService,
  resultService,
  segregatedAccountService,
  symbolService,
  underlyingSummaryService,
};

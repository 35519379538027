import { StressTestJobsActions } from './actions';
import {
  onFetchManualTestError,
  onFetchManualTestStart,
  onFetchManualTestSucceed,
  onManualTestToggleAddAccountModal,
  onManualTestToggleRemoveAccountModal,
  onManualTestToggleRemoveTradeModal,
  onRemoveDateValue,
  onRemoveExistingQuantityValue,
  onRemoveVirtualPositionValue,
  onSetDateValue,
  onSetExistingQuantityValue,
  onSetVirtualPositionValue,
  onStateToggleRemoveJobModal,
} from './handlers';
import type { StressTestJobsReducer, StressTestJobsState } from './types';

export const reducer: StressTestJobsReducer = (
  state,
  action,
): StressTestJobsState => {
  switch (action.type) {
    /**
     * ManualTest
     */
    case StressTestJobsActions.FetchManualTestError:
      return onFetchManualTestError(state, action.payload);

    case StressTestJobsActions.FetchManualTestStart:
      return onFetchManualTestStart(state);

    case StressTestJobsActions.FetchManualTestSucceed:
      return onFetchManualTestSucceed(state, action.payload);

    case StressTestJobsActions.ManualTestAddAccountModal:
      return onManualTestToggleAddAccountModal(state, action.payload);

    case StressTestJobsActions.ManualTestRemoveAccountModal:
      return onManualTestToggleRemoveAccountModal(state, action.payload);

    case StressTestJobsActions.ManualTestRemoveTradeModal:
      return onManualTestToggleRemoveTradeModal(state, action.payload);

    /**
     * Manual Test
     * Account Date Value
     */
    case StressTestJobsActions.SetDateValue:
      return onSetDateValue(state, action.payload);

    case StressTestJobsActions.RemoveDateValue:
      return onRemoveDateValue(state);

    /**
     * Manual Test
     * Existing Quantity Value
     */
    case StressTestJobsActions.SetExistingQuantity:
      return onSetExistingQuantityValue(state, action.payload);

    case StressTestJobsActions.RemoveExistingQuantity:
      return onRemoveExistingQuantityValue(state);

    /**
     * Manual Test
     * Virtual Position Value
     */
    case StressTestJobsActions.SetVirtualPosition:
      return onSetVirtualPositionValue(state, action.payload);

    case StressTestJobsActions.RemoveVirtualPosition:
      return onRemoveVirtualPositionValue(state);

    /**
     * State
     */
    case StressTestJobsActions.StateRemoveJobModal:
      return onStateToggleRemoveJobModal(state, action.payload);

    default:
      return state;
  }
};

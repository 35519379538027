export const enum StressTestJobsActions {
  FetchManualTestError = 'Manual Test: Fetch Error',
  FetchManualTestStart = 'Manual Test: Fetch Start',
  FetchManualTestSucceed = 'Manual Test: Fetch Succeed',

  ManualTestAddAccountModal = 'Manual Test: Add Account Modal',
  ManualTestRemoveAccountModal = 'Manual Test: Remove Account Modal',
  ManualTestRemoveTradeModal = 'Manual Test: Remove Trade Modal',

  StateRemoveJobModal = 'State: Remove Job Modal',

  SetDateValue = 'Manual Test: Set Date Value',
  RemoveDateValue = 'Manual Test: Remove Date Value',

  SetExistingQuantity = 'Manual Test: Set Existing Quantity',
  RemoveExistingQuantity = 'Manual Test: Remove Existing Quantity',

  SetVirtualPosition = 'Manual Test: Set Virtual Position',
  RemoveVirtualPosition = 'Manual Test: Remove Virtual Position',
}

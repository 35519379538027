import dayjs from 'dayjs';
import { ICellValue, IColumn, formatDate } from 'react-ui-kit-exante';

import type { TJobState } from 'types';

import { STATUS, STATUS_OPTIONS } from './constants';
import { IconButtonContainer, StatusContainer, StatusSpan } from './styled';

export type TGetColumnsProps = {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
};

export const getColumns = ({
  onFilter,
  onRemove,
}: TGetColumnsProps): IColumn<TJobState> => [
  {
    Header: 'Job ID',
    accessor: 'id',
    cellStyle: {
      cursor: 'pointer',
    },
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    onFilter,
    onRemove,
  },
  {
    Header: 'Account',
    accessor: 'account',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TJobState>) => {
      const { account } = original;

      return account || 'All Accounts';
    },
  },
  {
    Header: 'For Date',
    accessor: 'jobDate',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            withTime: true,
          }),
      },
    },
    Cell: ({ row: { original } }: ICellValue<TJobState>) => {
      const { jobDate } = original;

      if (!jobDate) {
        return '-';
      }

      return jobDate;
    },
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            withTime: true,
          }),
      },
    },
    Cell: ({ row: { original } }: ICellValue<TJobState>) => {
      if (
        dayjs(original.created_at).format('DD.MM.YYYY HH:mm') === 'Invalid Date'
      ) {
        return '-';
      }

      return dayjs(original.created_at).format('DD.MM.YYYY HH:mm');
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    filterOptions: STATUS_OPTIONS,
    type: 'select',
    onFilter,
    onRemove,
    Cell: ({ row: { original } }: ICellValue<TJobState>) => {
      if (STATUS[original.status]) {
        return (
          <StatusContainer>
            <IconButtonContainer
              iconColor={STATUS[original.status].color}
              iconName={STATUS[original.status].iconName}
              iconSize={18}
            />
            <StatusSpan status={STATUS[original.status].color}>
              {original.status}
            </StatusSpan>
          </StatusContainer>
        );
      }

      return original.status;
    },
  },
  {
    Header: 'Finished',
    accessor: 'finished_at',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            withTime: true,
          }),
      },
    },
    Cell: ({ row: { original } }: ICellValue<TJobState>) => {
      if (
        dayjs(original.finished_at).format('DD.MM.YYYY HH:mm') ===
        'Invalid Date'
      ) {
        return '-';
      }

      return dayjs(original.finished_at).format('DD.MM.YYYY HH:mm');
    },
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
  },
];

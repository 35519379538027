import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';

import { DefaultThemeProvider } from 'theme';

import { StyledSkeletonMUI } from './Skeleton.styled';

export const Skeleton = ({
  animation = 'wave',
  variant = 'rounded',
  ...props
}: SkeletonProps) => {
  return (
    <DefaultThemeProvider>
      <StyledSkeletonMUI {...props} variant={variant} animation={animation} />
    </DefaultThemeProvider>
  );
};

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateCountOfPages, useTableData } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { StressTestSettingsRoutes } from 'pages/StressTestSettings/routes';
import { parametersConfigService } from 'resources';
import type { TParameters, TParametersServerResponse } from 'types';

import { getColumns } from '../columns';
import { getDefaultPagination, getDefaultSorting } from '../constants';
import type { GetParametersProps, TUseParametersProps } from '../types';

export const useParameters = ({ dispatch, tableId }: TUseParametersProps) => {
  const navigate = useNavigate();

  const getParameters = useCallback(
    ({ params: { page, sorting, ...rest } }: GetParametersProps) => {
      return parametersConfigService.fetchConfigList({
        ...rest,
      });
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getParameters },
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getParameters, tableId],
  );

  const {
    data,
    fetchData: refetch,
    filters,
    isLoading,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
  } = useTableData<TParametersServerResponse>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const filteringProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const handleUpdateConfig = useCallback(
    async (_: TParameters, updated: TParameters) => {
      const { actions, id, ...payload } = updated;

      await parametersConfigService.updateConfig(id, {
        ...payload,
        ul_down: payload.ul_down / 100,
        ul_up: payload.ul_up / 100,
        iv_down: payload.iv_down / 100,
        iv_up: payload.iv_up / 100,
      });

      refetch();
    },
    [refetch],
  );

  const handleDeleteConfig = useCallback((rowValue: TParameters) => {
    const { id } = rowValue;

    dispatch({
      type: StressTestSettingsActions.ParametersRemoveConfigModal,
      payload: {
        id,
        show: true,
      },
    });
  }, []);

  const handleAddStressParameters = useCallback(() => {
    navigate(StressTestSettingsRoutes.STRESS_PARAMETERS_ADD);
  }, [navigate]);

  return {
    columns,
    data,
    filteringProps,
    handleAddStressParameters,
    handleDeleteConfig,
    handleUpdateConfig,
    isLoading,
    refetch,
    serverPaginationProps,
  };
};

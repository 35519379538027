import { AxiosResponse } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import type {
  TParams,
  TPeriodicJobCreatePayload,
  TPeriodicJobServer,
  TPeriodicJobState,
  TPeriodicJobUpdate,
} from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { normalizeData } from './helpers';
import { PeriodicJobRepository } from './periodicJob.repository';

export class PeriodicJobService {
  public async fetchPeriodicJobList(
    params: TParams,
  ): Promise<TPeriodicJobState> {
    try {
      const { data } = await PeriodicJobRepository.fetchPeriodicJobList(params);

      return {
        count: data.count,
        results: normalizeData(data.results),
      };
    } catch (err) {
      return {
        count: 0,
        results: [],
      };
    }
  }

  public async createPeriodicJob(
    payload: TPeriodicJobCreatePayload,
  ): Promise<TPeriodicJobServer> {
    const { data } = await PeriodicJobRepository.createPeriodicJob(payload);

    return data;
  }

  public async updatePeriodicJob(
    id: string,
    payload: TPeriodicJobUpdate,
  ): Promise<void> {
    try {
      await PeriodicJobRepository.updatePeriodicJob(id, payload);

      Notification.success({
        title: 'Runtime Setting successfully updated',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async deletePeriodicJob(id: string): Promise<AxiosResponse> {
    return PeriodicJobRepository.deletePeriodicJob(id);
  }
}

export enum Tabs {
  StressParameters = 0,
  ExcludeFromStress = 1,
  SegregatedClients = 2,
  RuntimeSettings = 3,
}

export const ACTIVE_TAB = Tabs.StressParameters;

export const EXCLUSION_TYPE = ['account', 'asset', 'client'];

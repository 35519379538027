import { DEFAULT_TRADE_VALUE } from 'constants/manualTest';

import { ActionsContainer } from '../styled';

import { RemoveAccount, RunJob } from './AccountActions';
import { RemoveExistingTrade } from './RemoveExistingTrade';
import { SaveDate } from './SaveDate';
import { SaveExistingQuantity } from './SaveExistingQuantity';
import { SaveVirtualPosition } from './SaveVirtualPosition';

type TActionsCellProps = {
  accountName: string;
  date: string;
  getLaunchParams: () => Promise<void>;
  quantity: number | null;
  tradeName: string;
};

export const ActionsCell = ({
  accountName,
  date,
  getLaunchParams,
  quantity,
  tradeName,
}: TActionsCellProps) => {
  if (quantity) {
    return (
      <ActionsContainer>
        <SaveExistingQuantity
          accountName={accountName}
          date={date}
          getLaunchParams={getLaunchParams}
          quantity={quantity}
          tradeName={tradeName}
        />
        <RemoveExistingTrade
          accountName={accountName}
          date={date}
          tradeName={tradeName}
        />
      </ActionsContainer>
    );
  }

  if (tradeName === DEFAULT_TRADE_VALUE) {
    return (
      <SaveVirtualPosition
        accountName={accountName}
        date={date}
        getLaunchParams={getLaunchParams}
      />
    );
  }

  return (
    <ActionsContainer>
      <SaveDate
        accountName={tradeName}
        date={date}
        getLaunchParams={getLaunchParams}
      />
      <RunJob accountName={tradeName} date={date} />
      <RemoveAccount accountName={tradeName} date={date} />
    </ActionsContainer>
  );
};

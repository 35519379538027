import { Controller } from 'react-hook-form';
import { IconButton, Input, Panel } from 'react-ui-kit-exante';

import { CONTROL_CLS } from './constants';
import { useParametersAdd } from './hooks';
import { ActionsContainer, ControlsContainer } from './styled';

export const ParametersAdd = () => {
  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  } = useParametersAdd();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel title="Add Stress Parameter" action={renderActions()} />

      <ControlsContainer>
        <Controller
          name="position_type"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Type"
              sx={CONTROL_CLS}
              warning={dirtyFields.position_type}
              error={Boolean(errors.position_type)}
              message={errors.position_type?.message}
            />
          )}
        />
        <Controller
          name="underlying"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Underlying"
              sx={CONTROL_CLS}
              warning={dirtyFields.underlying}
              error={Boolean(errors.underlying)}
              message={errors.underlying?.message}
            />
          )}
        />
        <Controller
          name="ul_up"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Delta Shift Up, %"
              sx={CONTROL_CLS}
              warning={dirtyFields.ul_up}
              error={Boolean(errors.ul_up)}
              message={errors.ul_up?.message}
            />
          )}
        />
        <Controller
          name="ul_down"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Delta Shift Down, %"
              sx={CONTROL_CLS}
              warning={dirtyFields.ul_down}
              error={Boolean(errors.ul_down)}
              message={errors.ul_down?.message}
            />
          )}
        />
        <Controller
          name="iv_up"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="IV Shift Up, %"
              sx={CONTROL_CLS}
              warning={dirtyFields.iv_up}
              error={Boolean(errors.iv_up)}
              message={errors.iv_up?.message}
            />
          )}
        />
        <Controller
          name="iv_down"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="IV Shift Down, %"
              sx={CONTROL_CLS}
              warning={dirtyFields.iv_down}
              error={Boolean(errors.iv_down)}
              message={errors.iv_down?.message}
            />
          )}
        />
      </ControlsContainer>
    </form>
  );
};

import { AutocompleteAsync, styled } from 'react-ui-kit-exante';

export const AutocompleteAsyncContainer = styled(AutocompleteAsync)(() => ({
  minWidth: '300px',

  '&.AutoCompleteSmall label.MuiInputLabel-sizeSmall': {
    fontSize: '12px',
    lineHeight: '18px',
    transform: 'translate(12px, 7px) scale(1)',
  },
  '&.AutoCompleteSmall label.MuiInputLabel-shrink': {
    fontSize: '9px',
    lineHeight: '14px',
    transform: 'translate(12px, 1px) scale(1)',
  },
  '&.AutoCompleteSmall .MuiAutocomplete-inputRoot.MuiInputBase-sizeSmall': {
    paddingBottom: '2px',
    paddingTop: '14px',
  },
}));

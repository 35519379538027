import type { TManualTestServer } from 'types';
import { apiRequest } from 'utils';

import { LAUNCH_PARAMS_API } from './endpoints';

export class LaunchParamsRepository {
  public static fetchLaunchParams() {
    return apiRequest<TManualTestServer[]>({
      url: LAUNCH_PARAMS_API,
    });
  }

  public static createLaunchParam(payload: TManualTestServer[]) {
    return apiRequest<TManualTestServer[]>({
      data: payload,
      method: 'POST',
      url: LAUNCH_PARAMS_API,
    });
  }
}

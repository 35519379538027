import { ISortBy } from 'react-ui-kit-exante';

import type { TOption } from 'types';

import type { TStatus } from './types';

export const DISPLAYED_COLUMN_KEYS = [
  'account',
  'jobDate',
  'comment',
  'created_at',
  'finished_at',
  'id',
  'status',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
export const getDefaultPagination = () => ({
  limit: PAGE_SIZE,
  skip: 0,
});
export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'created_at', desc: true }];
}
export const DEFAULT_SORTING = getDefaultSorting();

export const STATUS_OPTIONS: TOption[] = [
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Failed',
    value: 'Failed',
  },
  {
    label: 'Running',
    value: 'Running',
  },
];

export const STATUS: Record<string, TStatus> = {
  Completed: {
    color: 'action',
    iconName: 'CircleCheckIcon',
  },
  Failed: {
    color: 'radical',
    iconName: 'WarningCircleIcon',
  },
  Running: {
    color: 'warning',
    iconName: 'CircleClockIcon',
  },
};

export const STATUS_FAILED = 'Failed';

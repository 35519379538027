import { StressTestSettingsState } from './types';

export const initialState: StressTestSettingsState = {
  parametersRemoveConfigModal: {
    id: '',
    show: false,
  },
  removeExcludeModal: {
    id: '',
    show: false,
  },
  removeSegregatedAccountModal: {
    id: '',
    show: false,
  },
  runtimeSettingRemoveModal: {
    id: '',
    show: false,
  },
};

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { IconButton, Notification, Tooltip } from 'react-ui-kit-exante';

import { jobService, launchParamsService } from 'resources';
import type {
  TCreateJobByDatePayload,
  TCreateJobMultipleAccountsPayload,
} from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { StressTestJobsActions, StressTestJobsContext } from '../context';

import { AddAccountModal } from './AddAccountModal';
import { RemoveAccountModal } from './RemoveAccountModal';
import { RemoveTradeModal } from './RemoveTradeModal';
import { getColumns } from './columns';
import { parseDate, transformData } from './helpers';
import { Loading, TableContainer } from './styled';

export const ManualTest = () => {
  const { state, dispatch } = useContext(StressTestJobsContext);

  const getLaunchParams = useCallback(async () => {
    dispatch({
      type: StressTestJobsActions.FetchManualTestStart,
    });

    const data = await launchParamsService.fetchLaunchParams();

    dispatch({
      type: StressTestJobsActions.FetchManualTestSucceed,
      payload: data,
    });
  }, []);

  const columns = useMemo(() => getColumns({ getLaunchParams }), []);

  const handleAddAccountModal = useCallback(() => {
    dispatch({
      type: StressTestJobsActions.ManualTestAddAccountModal,
      payload: true,
    });
  }, []);

  const handleRunAllJobs = useCallback(async () => {
    if (!state.manualTestData) {
      Notification.error({
        title: 'No accounts with positions found to run jobs',
      });

      return;
    }

    try {
      const payload = transformData(state.manualTestData).map((item) => {
        return {
          ...item,
          date: parseDate(item.date),
        };
      });

      const payloadMultipleAccounts = [] as TCreateJobMultipleAccountsPayload[];
      const payloadByDate = [] as TCreateJobByDatePayload[];

      payload.forEach((item) => {
        if (item.account) {
          payloadMultipleAccounts.push({
            account: item.account,
            date: item.date,
            v_trades: item.trades || {},
          });
        } else {
          payloadByDate.push({
            date: item.date,
          });
        }
      });

      if (payloadMultipleAccounts.length) {
        await jobService.createJobMultipleAccounts(payloadMultipleAccounts);
      }

      if (payloadByDate.length) {
        await Promise.all(
          payloadByDate.map(async (date) => {
            await jobService.createJobByDate(date);
          }),
        );
      }

      const payloadMultipleAccountsCount =
        payloadMultipleAccounts.length > 0 ? 1 : 0;
      const payloadByDateCount = payloadByDate.length > 0 ? 1 : 0;
      const jobsCount = payloadMultipleAccountsCount + payloadByDateCount;

      if (jobsCount > 0) {
        Notification.success({
          title: `${jobsCount} jobs successfully started`,
        });
      } else {
        Notification.warning({
          title: 'Jobs were not started',
        });
      }
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }, [state.manualTestData]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddManualTest',
        component: (
          <Tooltip title="Add Test">
            <IconButton
              iconColor="action"
              iconName="AddIcon"
              iconSize={24}
              label="Add Test"
              onClick={handleAddAccountModal}
            />
          </Tooltip>
        ),
      },
      // {
      //   key: 'RunAllManualTests',
      //   component: (
      //     <Tooltip title="Run All Jobs">
      //       <IconButton
      //         iconColor="action"
      //         iconName="PlayIcon"
      //         iconSize={24}
      //         onClick={handleRunAllJobs}
      //       />
      //     </Tooltip>
      //   ),
      // },
    ],
    [handleAddAccountModal, handleRunAllJobs],
  );

  useEffect(() => {
    getLaunchParams();
  }, []);

  if (!state.ready || state.fetchManualTestStatus.pending) {
    return <Loading size="l" />;
  }

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={state.manualTestData}
        expanded={{
          listOfInitialExpandedRowKeys: true,
        }}
        isFlexLayout
        saveViewParamsAfterLeave
        tableId="ManualTestTable"
      />

      {state.manualTestAddAccountModal && (
        <AddAccountModal getLaunchParams={getLaunchParams} />
      )}

      {state.manualTestRemoveAccountModal.date &&
        state.manualTestRemoveAccountModal.show && (
          <RemoveAccountModal getLaunchParams={getLaunchParams} />
        )}

      {state.manualTestRemoveTradeModal.accountName &&
        state.manualTestRemoveTradeModal.tradeName &&
        state.manualTestRemoveTradeModal.show && (
          <RemoveTradeModal getLaunchParams={getLaunchParams} />
        )}
    </>
  );
};

import { StressTestJobsState, TRemoveTrade } from '../types';

export const onManualTestToggleRemoveTradeModal = (
  state: StressTestJobsState,
  payload: TRemoveTrade,
): StressTestJobsState => ({
  ...state,

  manualTestRemoveTradeModal: payload,
});

import { Notification } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { excludeService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import type { TUseDeleteExcludeModalProps } from '../types';

export const useDeleteExcludeModal = ({
  dispatch,
  refetch,
  state,
}: TUseDeleteExcludeModalProps) => {
  const handleModalClose = () => {
    dispatch({
      type: StressTestSettingsActions.RemoveExcludeModal,
      payload: {
        id: '',
        show: false,
      },
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      if (state.removeExcludeModal.id) {
        await excludeService.deleteExclude(state.removeExcludeModal.id);

        Notification.success({
          title: `Stress Test Exclude successfully deleted with ID ${state.removeExcludeModal.id}`,
        });

        refetch();
      }
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      handleModalClose();
    }
  };

  return {
    handleDeleteConfirm,
    handleModalClose,
  };
};

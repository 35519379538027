import { Loader, Table, styled } from 'react-ui-kit-exante';

export const TableContainer = styled(Table)(() => ({
  '& .Table': {
    overflow: 'visible',
  },
}));

export const ActionsContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  marginRight: '16px',
}));

export const Loading = styled(Loader)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  maxHeight: '70vh',
  minHeight: '40vh',
  width: '100%',
}));

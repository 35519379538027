import { createContext, Dispatch } from 'react';

import { initialState } from './initialState';
import {
  StressTestSettingsActionsType,
  StressTestSettingsState,
} from './types';

export const StressTestSettingsContext = createContext<{
  state: StressTestSettingsState;
  dispatch: Dispatch<StressTestSettingsActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});

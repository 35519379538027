import { Tabs, styled } from 'react-ui-kit-exante';

export const TabsWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const TabsHeader = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  padding: '24px 24px 12px 24px',
}));

export const TabsHeaderTitle = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '32px',
  lineHeight: '48px',
  maxWidth: '960px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const TabsContainer = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
}));

import type { TOption } from 'types';

export const DISPLAYED_COLUMN_KEYS = [
  'account',
  'comment',
  'name',
  'isEnabled',
  'runOnDays',
  'startTime',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];

export const getDefaultPagination = () => ({
  limit: PAGE_SIZE,
  skip: 0,
});

export const RUN_ON_DAYS_OPTIONS: TOption[] = [
  {
    label: 'Mo',
    value: 'Mo',
  },
  {
    label: 'Tu',
    value: 'Tu',
  },
  {
    label: 'We',
    value: 'We',
  },
  {
    label: 'Th',
    value: 'Th',
  },
  {
    label: 'Fr',
    value: 'Fr',
  },
];

import type { StressTestSettingsState, TRemoveSetting } from '../types';

export const onToggleRuntimeSettingRemoveModal = (
  state: StressTestSettingsState,
  payload: TRemoveSetting,
): StressTestSettingsState => ({
  ...state,

  runtimeSettingRemoveModal: payload,
});

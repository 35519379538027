import { styled } from 'theme';

import { blockNonNativeProps } from '../../../helpers';

import { TRANSIENT_PROPS } from './const';
import { IconButtonProps } from './types';

interface IIconProps {
  iconSize?: number;
  iconName: string;
}

export const StyledIconButton = styled('button', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_PROPS),
})<IconButtonProps>(({ color, theme }) => {
  return {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    color,
    svg: {
      color,
    },
    '&:hover': {
      color: theme?.color.icon.promo,
      svg: {
        color: theme?.color.icon.promo,
      },
    },
    '&:active': {
      color: theme.color.icon.primary,
      svg: {
        color: theme?.color.icon.primary,
      },
    },
    '&:disabled': {
      color: theme?.color.icon.secondary,
      opacity: 0.4,
      cursor: 'initial',
      svg: {
        color: theme?.color.icon.secondary,
      },
    },
  };
}) as React.ComponentType<IconButtonProps>;

export const StyledIcon = styled('span', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_PROPS),
})<IIconProps>(({ iconSize }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: iconSize,
  width: iconSize,
  height: iconSize,

  svg: {
    width: '100%',
    height: 'auto',
  },
}));

export const StyledText = styled('span')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '8px',
  fontSize: '15px',
  fontFamily: theme?.font.main,
  fontWeight: 500,
}));

import type { TParametersServerResponse } from 'types';

export const transformData = (
  data: TParametersServerResponse,
): TParametersServerResponse => {
  const results = data.results.map((item) => ({
    ...item,
    ul_down: item.ul_down * 100,
    ul_up: item.ul_up * 100,
    iv_down: item.iv_down * 100,
    iv_up: item.iv_up * 100,
  }));

  return {
    count: data.count,
    results,
  };
};

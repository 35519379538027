import { AxiosResponse } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import type { TParams, TExcludeServerResponse, TExcludePayload } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { ExcludeRepository } from './exclude.repository';

export class ExcludeService {
  public async fetchExcludeList(
    params: TParams,
  ): Promise<TExcludeServerResponse> {
    try {
      const { data } = await ExcludeRepository.fetchExcludeList(params);

      return {
        count: data.count,
        results: data.results,
      };
    } catch (err) {
      sendUiKitErrorNotification(err);

      return {
        count: 0,
        results: [],
      };
    }
  }

  public async createExclude(
    payload: TExcludePayload,
  ): Promise<TExcludeServerResponse> {
    const { data } = await ExcludeRepository.createExclude(payload);

    return data;
  }

  public async updateExclude(
    id: string,
    payload: TExcludePayload,
  ): Promise<void> {
    try {
      await ExcludeRepository.updateExclude(id, payload);

      Notification.success({
        title: 'Stress Test Exclude successfully updated',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async deleteExclude(id: string): Promise<AxiosResponse> {
    return ExcludeRepository.deleteExclude(id);
  }
}

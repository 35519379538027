import type { TManualTest, TManualTestServer } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { normalizeData } from './helpers';
import { LaunchParamsRepository } from './launchParams.repository';

export class LaunchParamsService {
  public async fetchLaunchParams(): Promise<TManualTest[]> {
    try {
      const { data } = await LaunchParamsRepository.fetchLaunchParams();

      return normalizeData(data);
    } catch (err) {
      sendUiKitErrorNotification(err);

      return [];
    }
  }

  public async createLaunchParam(payload: TManualTestServer[]): Promise<void> {
    try {
      await LaunchParamsRepository.createLaunchParam(payload);
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }
}

import { Notification } from 'react-ui-kit-exante';

import type { TUnderlyingSummaryPayload } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { UnderlyingSummaryRepository } from './underlyingSummary.repository';

export class UnderlyingSummaryService {
  public async updateUnderlyingSummary(
    underlyingID: string,
    payload: TUnderlyingSummaryPayload,
    message = 'Comment successfully updated',
  ): Promise<void> {
    try {
      await UnderlyingSummaryRepository.updateUnderlyingSummary(
        underlyingID,
        payload,
      );

      Notification.success({
        title: message,
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }
}

import { StressTestJobsState, TRemoveJob } from '../types';

export const onStateToggleRemoveJobModal = (
  state: StressTestJobsState,
  payload: TRemoveJob,
): StressTestJobsState => ({
  ...state,

  stateRemoveJobModal: payload,
});

import * as cookies from 'js-cookie';

import {
  ACCESS_TOKEN_COOKIE_KEY,
  SESSION_ID_COOKIE_KEY,
} from 'constants/session';

export const getAccessTokenFromCookies = () =>
  cookies.get(ACCESS_TOKEN_COOKIE_KEY);

export function getSessionIdFromCookie(
  sessionIdCookieKey = SESSION_ID_COOKIE_KEY,
) {
  return cookies.get(sessionIdCookieKey);
}

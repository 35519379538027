import { isEmpty } from 'lodash';

import type { TJobStateServer, TParams } from 'types';
import { setStartEndForDateRangeString } from 'utils';

export function transformParams(params: TParams) {
  const paramsClone = { ...params };

  if (paramsClone.created_at) {
    const createTime = paramsClone.created_at as string[];
    const createTimeFormatted = setStartEndForDateRangeString(createTime);

    paramsClone.from_created_at = createTimeFormatted[0];
    paramsClone.to_created_at = createTimeFormatted[1];

    delete paramsClone.created_at;
  }

  return paramsClone;
}

export const transformData = (data: TJobStateServer): TJobStateServer => {
  const results = data.results.map((result) => {
    let jobDate = result.launch_params?.data
      ?.filter((launchParam) => launchParam.date)
      ?.map((launchParam) => launchParam.date)
      ?.join(', ');

    if (result.launch_params?.date) {
      jobDate = result.launch_params?.date;
    }

    if (!jobDate) {
      jobDate = '-';
    }

    return {
      ...result,
      account: result.launch_params?.data
        ?.filter(
          (launchParam) => launchParam.account && !isEmpty(launchParam.account),
        )
        ?.map((launchParam) => launchParam.account)
        ?.join(', '),
      jobDate,
    };
  });

  return {
    ...data,
    results,
  };
};

import type { TParametersPayload } from 'types';

export const CONTROL_CLS = { mt: '16px', width: '290px' };

export const DEFAULT_VALUES: TParametersPayload = {
  position_type: '',
  underlying: '',
  ul_down: 0,
  ul_up: 0,
  iv_down: 0,
  iv_up: 0,
};

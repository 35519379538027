import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  InputDatePicker,
  Modal,
  Notification,
} from 'react-ui-kit-exante';

import { DATE_FORMAT_D_M_Y } from 'constants/common';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';
import type { TManualTestServer } from 'types';

import { transformData } from '../helpers';

import { CONTROL_CLS } from './constants';
import { FormContainer } from './styled';
import { VALIDATION_SCHEMA } from './validationSchema';

type AddAccountModalProps = {
  getLaunchParams: () => Promise<void>;
};

export const AddAccountModal = ({ getLaunchParams }: AddAccountModalProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);

  const methods = useForm({
    defaultValues: {
      account: '',
      date: dayjs(new Date()).format(DATE_FORMAT_D_M_Y),
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
  } = methods;

  const handleModalClose = () => {
    dispatch({
      type: StressTestJobsActions.ManualTestAddAccountModal,
      payload: false,
    });
  };

  const handleAddAccountConfirm = useCallback(async () => {
    const { account, date } = getValues();

    if (!date) {
      Notification.warning({
        title: 'Date is required field',
      });

      return;
    }

    const payload: TManualTestServer = {
      date: dayjs(date).format(DATE_FORMAT_D_M_Y),
    };

    if (account.trim()) {
      payload.account = account;
    }

    const transformedPayload = transformData(state.manualTestData);

    /**
     * Validation for 2 identical accounts with the same dates
     * Validation for identical All Accounts with the same dates
     */
    const hasSameAccountDate = transformedPayload.some((item) => {
      if (
        (item.account === payload.account ||
          (!item.account && !payload.account)) &&
        item.date === payload.date
      ) {
        return true;
      }

      return false;
    });

    if (hasSameAccountDate) {
      let accountText = `account ${payload.account}`;

      if (!payload.account) {
        accountText = 'All Accounts';
      }

      Notification.warning({
        title: `There is identical ${accountText} with date ${payload.date}`,
      });

      return;
    }

    transformedPayload.push(payload);

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    handleModalClose();
  }, [state.manualTestData]);

  return (
    <Modal
      confirmButton={{
        confirmButtonName: 'Confirm',
        handleConfirm: handleAddAccountConfirm,
      }}
      isOpened={state.manualTestAddAccountModal}
      onClose={handleModalClose}
      title="Add Account"
    >
      <FormContainer>
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <InputDatePicker
              {...field}
              dateFormat="dd.MM.yyyy"
              inputProps={{
                label: 'Date',
                warning: dirtyFields.date,
                error: Boolean(errors.date),
                message: errors.date?.message,
                size: 'medium',
                sx: {
                  ...CONTROL_CLS,
                },
              }}
              onChange={field.onChange}
              selected={field.value}
            />
          )}
        />
        <Controller
          name="account"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              label="Account"
              sx={CONTROL_CLS}
              warning={dirtyFields.account}
              error={Boolean(errors.account)}
              message={errors.account?.message}
            />
          )}
        />
      </FormContainer>
    </Modal>
  );
};

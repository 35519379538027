import {
  TFactor,
  type TClient,
  type TClientDetailsServer,
  type TClientServerResponse,
  type TFactorDetailsServer,
  type TFactorResult,
  type TFactorServerResponse,
  TClientResult,
} from 'types';

export const normalizeFactor = (data: TFactorServerResponse): TFactorResult => {
  const results: TFactor[] = data.results.map((item) => {
    const getFactorSubRows = (details: TFactorDetailsServer[]) => {
      return details.map((detail) => {
        return {
          clientOrAccount: detail.account_id,
          scenarioIv: detail.stress_vola,
          scenarioPnl: detail.stress_pnl,
          scenarioDelta: detail.stress_delta,
        };
      });
    };

    return {
      comment: item.comment,
      factor: item.underlying,
      summaryId: item.summary_id || undefined,
      subRows: getFactorSubRows(item.details),
      var: item.stressed_nav,
    };
  });

  return {
    count: data.count,
    results,
  };
};

export const normalizeClient = (data: TClientServerResponse): TClientResult => {
  const results: TClient[] = data.results.map((item) => {
    const getFactorSubRows = (details: TClientDetailsServer[]) => {
      return details.map((detail) => {
        return {
          client: detail.underlying,
          scenarioIv: detail.stress_vola,
          scenarioPnl: detail.stress_pnl,
          scenarioDelta: detail.stress_delta,
        };
      });
    };

    return {
      accTotalId: item.acc_total_id || undefined,
      comment: item.comment,
      factor: item.account_id,
      nav: item.nav,
      scenario: item.description,
      subRows: getFactorSubRows(item.details),
      var: item.stressed_nav,
    };
  });

  return {
    count: data.count,
    results,
  };
};

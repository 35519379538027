import { useContext, useMemo } from 'react';
import { IconButton, Modal } from 'react-ui-kit-exante';

import { prepareTableId } from 'utils';

import { StressTestSettingsContext } from '../context';

import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { useDeleteParametersModal, useParameters } from './hooks';
import { TableContainer } from './styled';

const tableId = prepareTableId('parameters');

export const Parameters = () => {
  const { state, dispatch } = useContext(StressTestSettingsContext);

  const {
    columns,
    data,
    filteringProps,
    handleAddStressParameters,
    handleDeleteConfig,
    handleUpdateConfig,
    isLoading,
    refetch,
    serverPaginationProps,
  } = useParameters({ dispatch, tableId });

  const { handleDeleteConfirm, handleModalClose } = useDeleteParametersModal({
    dispatch,
    refetch,
    state,
  });

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleUpdateConfig,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDeleteConfig,
        },
      ],
    }),
    [handleUpdateConfig, handleDeleteConfig],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddStressParameters',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Parameter"
            onClick={handleAddStressParameters}
          />
        ),
      },
    ],
    [handleAddStressParameters, isLoading, refetch],
  );

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={data?.results || []}
        defaultSortBy={DEFAULT_SORTING}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        filteringProps={filteringProps}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        rowActions={rowActions}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        tableId={tableId}
      />

      <Modal
        isOpened={state.parametersRemoveConfigModal.show}
        onClose={handleModalClose}
        title="Delete Stress Parameter Config"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleDeleteConfirm,
        }}
      >
        <div>Are you sure you want to delete the stress parameter config?</div>
      </Modal>
    </>
  );
};

import { ICellValue, IColumn, formatDate } from 'react-ui-kit-exante';

import type { TParameters } from 'types';

import { DATE_WITH_TIME } from './constants';

export type TGetColumnsProps = {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
};

export const getColumns = ({
  onFilter,
  onRemove,
}: TGetColumnsProps): IColumn<TParameters>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
  },
  {
    Header: 'Instrument Type',
    accessor: 'position_type',
    editParams: {
      editable: true,
      inputType: 'text',
    },
    onFilter,
    onRemove,
  },
  {
    Header: 'Underlying (Factor)',
    accessor: 'underlying',
    editParams: {
      editable: true,
      inputType: 'text',
    },
    onFilter,
    onRemove,
  },
  {
    Header: 'Delta Shift Up, %',
    accessor: 'ul_up',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    Cell: ({ row: { original } }: ICellValue<TParameters>) => {
      if (Number.isNaN(Number(original.ul_up))) {
        return '';
      }

      return original.ul_up.toFixed(2);
    },
  },
  {
    Header: 'Delta Shift Down, %',
    accessor: 'ul_down',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    Cell: ({ row: { original } }: ICellValue<TParameters>) => {
      if (Number.isNaN(Number(original.ul_down))) {
        return '';
      }

      return original.ul_down.toFixed(2);
    },
  },
  {
    Header: 'IV Shift Up, %',
    accessor: 'iv_up',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    Cell: ({ row: { original } }: ICellValue<TParameters>) => {
      if (Number.isNaN(Number(original.iv_up))) {
        return '';
      }

      return original.iv_up.toFixed(2);
    },
  },
  {
    Header: 'IV Shift Down, %',
    accessor: 'iv_down',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    Cell: ({ row: { original } }: ICellValue<TParameters>) => {
      if (Number.isNaN(Number(original.iv_down))) {
        return '';
      }

      return original.iv_down.toFixed(2);
    },
  },
  {
    Header: 'Updated At',
    accessor: 'updated_at',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_WITH_TIME,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TParameters>) =>
      formatDate({
        date: original.updated_at,
        format: DATE_WITH_TIME,
      }),
  },
];

import { StressTestJobsState } from '../types';

export const onManualTestToggleAddAccountModal = (
  state: StressTestJobsState,
  payload: boolean,
): StressTestJobsState => ({
  ...state,

  manualTestAddAccountModal: payload,
});

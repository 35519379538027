import { Notification } from 'react-ui-kit-exante';

import type { TAccountTotalPayload } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { AccountTotalRepository } from './accountTotal.repository';

export class AccountTotalService {
  public async updateAccountTotal(
    accountTotalID: string,
    payload: TAccountTotalPayload,
    message = 'Comment successfully updated',
  ): Promise<void> {
    try {
      await AccountTotalRepository.updateAccountTotal(accountTotalID, payload);

      Notification.success({
        title: message,
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }
}

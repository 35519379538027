import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { periodicJobService } from 'resources';
import type {
  TJobArgumentsWithAccount,
  TJobArgumentsWithoutAccount,
  TPeriodicJobCreate,
  TPeriodicJobCreatePayload,
} from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { getStartTime, normalizeDaysOfWeek } from '../../helpers';
import { DEFAULT_VALUES } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

export const useRuntimeSettingAdd = () => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(ROUTES.STRESS_TEST_SETTINGS);
  }, [navigate]);

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
  } = methods;

  const onSubmit = async (payload: TPeriodicJobCreate) => {
    try {
      const runOnDays = payload.runOnDays.map((item) => item.value);

      let jobArguments:
        | TJobArgumentsWithAccount[]
        | TJobArgumentsWithoutAccount;

      if (payload.account) {
        jobArguments = [
          {
            account: payload.account,
            date: null,
            v_trades: {},
          },
        ];
      } else {
        jobArguments = {
          date: null,
        };
      }

      const createPayload: TPeriodicJobCreatePayload = {
        arguments: jobArguments,
        comment: payload.comment,
        day_of_week: normalizeDaysOfWeek(runOnDays),
        hour: getStartTime(payload.startTime, 0),
        is_enabled: payload.isEnabled,
        minute: getStartTime(payload.startTime, 1),
        name: payload.name,
      };

      await periodicJobService.createPeriodicJob(createPayload);

      Notification.success({
        title: 'Runtime Setting successfully created',
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  return {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  };
};

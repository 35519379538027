import { ICellValue, IColumn } from 'react-ui-kit-exante';

import type { TPeriodicJob } from 'types';

import { RUN_ON_DAYS_OPTIONS } from './constants';

export const getColumns = (): IColumn<TPeriodicJob>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
  },
  {
    Header: 'Account ID',
    accessor: 'account',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || 'All Accounts',
      },
    },
    editParams: {
      editable: true,
      inputType: 'text',
    },
    Cell: ({ row: { original } }: ICellValue<TPeriodicJob>) =>
      original.account || 'All Accounts',
  },
  {
    Header: 'Name',
    accessor: 'name',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Enabled',
    accessor: 'isEnabled',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'checkbox',
    },
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    editParams: {
      editable: true,
      inputType: 'text',
      additionalInputParams: {
        placeholder: 'hh:mm',
      },
    },
  },
  {
    Header: 'Run On Days',
    accessor: 'runOnDays',
    editParams: {
      editable: true,
      inputType: 'multiSelect',
      options: RUN_ON_DAYS_OPTIONS,
    },
    Cell: ({ row: { original } }: ICellValue<TPeriodicJob>) => {
      const { runOnDays } = original;

      const runOnDaysUpdated = runOnDays
        ?.filter((item: string) => item !== '-')
        .join()
        .replace(/,/g, ', ');

      return <span style={{ whiteSpace: 'pre-wrap' }}>{runOnDaysUpdated}</span>;
    },
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    editParams: {
      editable: true,
      inputType: 'textArea',
    },
  },
];

import { Table, styled } from 'react-ui-kit-exante';

import type { TPeriodicJob } from 'types';

export const TableContainer = styled(Table<TPeriodicJob>)(() => ({
  '& > div': {
    overflowY: 'visible',
  },
  '& div[data-test-id="table__body--editable-cell"]': {
    overflow: 'visible',
  },
  '& div[data-test-id="table__body--editable-cell"] div.MuiAutocomplete-inputRoot':
    {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '130px',
    },
}));

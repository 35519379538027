import axios from 'axios';
import { isEmpty } from 'lodash';

import type { TErrorInfo } from 'types';

export function transformErrorToInfoObject(error: unknown): TErrorInfo | null {
  try {
    if (axios.isAxiosError(error)) {
      const response = error.response;

      if (response) {
        const { data, request, status, statusText } = response;

        let dataStringified = '';

        if (!isEmpty(data)) {
          dataStringified = JSON.stringify(data);
        }

        return {
          message: data?.message || dataStringified || statusText,
          statusCode: data?.statusCode || status,
          url: request.responseURL,
        };
      }
    }

    if (error instanceof Error) {
      return { message: `${error.name}: ${error.message}` };
    }

    return null;
  } catch (err) {
    return null;
  }
}

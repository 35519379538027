import { PropsWithChildren } from 'react';

import { DefaultThemeProvider } from 'theme';

import { FullscreenOverlayStyled } from './FullscreenOverlay.styled';
import type { TModal } from './FullscreenOverlay.types';

export const FullscreenOverlay = ({
  children,
  className,
  disableEscapeKeyDown = false,
  keepMounted = true,
  open = false,
  onClose,
  ...props
}: PropsWithChildren<TModal>) => {
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <DefaultThemeProvider>
      <FullscreenOverlayStyled
        disableEscapeKeyDown={disableEscapeKeyDown}
        keepMounted={keepMounted}
        onClose={onCloseHandler}
        {...props}
        open={open}
      >
        <div className={className} data-test-id="fullscreen-overlay-div">
          {children}
        </div>
      </FullscreenOverlayStyled>
    </DefaultThemeProvider>
  );
};

import { number, object, string } from 'yup';

export const VALIDATION_SCHEMA = object().shape({
  position_type: string().required(),
  underlying: string().required(),
  ul_down: number().nullable(),
  ul_up: number().nullable(),
  iv_down: number().nullable(),
  iv_up: number().nullable(),
});

import { StressTestJobsState } from '../types';

export const onRemoveDateValue = (
  state: StressTestJobsState,
): StressTestJobsState => {
  return {
    ...state,
    accountDateValue: {
      accountName: undefined,
      date: '',
      initialDate: '',
    },
  };
};

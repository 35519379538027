import type { TManualTest } from 'types';

import { StressTestJobsState } from '../types';

export const onFetchManualTestSucceed = (
  state: StressTestJobsState,
  payload: TManualTest[],
): StressTestJobsState => ({
  ...state,

  ready: true,

  fetchManualTestStatus: {
    error: null,
    pending: false,
    succeed: true,
  },

  manualTestData: payload,
});

import { MenuItem, MenuList, Paper, Popper } from '@mui/material';

import { styled } from 'theme';

export const StyledCalendarSelectPopper = styled(Popper)({
  zIndex: 1,
});

export const StyledCalendarSelectPaper = styled(Paper)(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: '400px',
  border: '1px solid',
  borderColor: theme?.color?.dropdown?.border,
  borderRadius: '4px',
}));

export const StyledCalendarMenuList = styled(MenuList)({
  padding: 0,
  border: 'none',
});

export const StyledCalendarMenuItem = styled(MenuItem)(({ theme }) => {
  const colors = theme?.color?.datepicker;

  return {
    padding: '8px 24px',
    fontSize: theme?.size?.text?.md,
    fontFamily: theme?.font?.main,
    lineHeight: '16px',
    color: colors?.colorDay,
    backgroundColor: colors?.background,
    minHeight: 'min-content',

    '&:hover, &:focus-visible': {
      backgroundColor: colors?.dayHover,
    },

    '&.Mui-selected': {
      backgroundColor: colors?.daySelected,

      '&:hover': {
        backgroundColor: colors?.dayHover,
      },
    },
  };
});

import type {
  TClientServerResponse,
  TFactorServerResponse,
  TParams,
} from 'types';
import { apiRequest } from 'utils';

import { RESULT_CLIENT_API, RESULT_FACTOR_API } from './endpoints';

export class ResultRepository {
  public static fetchFactorList(params: TParams) {
    return apiRequest<TFactorServerResponse>({
      params,
      url: RESULT_FACTOR_API,
    });
  }

  public static fetchClientList(params: TParams) {
    return apiRequest<TClientServerResponse>({
      params,
      url: RESULT_CLIENT_API,
    });
  }
}

import { isEmpty } from 'lodash';
import { useCallback, useContext } from 'react';
import { IconButton, Notification, Tooltip } from 'react-ui-kit-exante';

import { transformData } from 'pages/StressTestJobs/ManualTest/helpers';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';
import { launchParamsService } from 'resources';

type TSaveExistingQuantityProps = {
  accountName: string;
  date: string;
  getLaunchParams: () => Promise<void>;
  quantity: number | null;
  tradeName: string;
};

export const SaveExistingQuantity = ({
  accountName,
  date,
  getLaunchParams,
  quantity,
  tradeName,
}: TSaveExistingQuantityProps) => {
  const { state, dispatch } = useContext(StressTestJobsContext);
  const { existingQuantityValue, manualTestData } = state;

  const handleSaveQuantity = useCallback(async () => {
    if (Number.isNaN(Number(existingQuantityValue.quantity))) {
      Notification.warning({ title: 'Quantity is not a valid number' });

      return;
    }

    if (existingQuantityValue.quantity === 0) {
      Notification.warning({ title: 'Quantity 0 is not applicable' });

      return;
    }

    const transformedPayload = transformData(manualTestData).map((item) => {
      const updatedItem = item;

      if (item.account === accountName && item.date === date) {
        updatedItem.trades = {
          ...updatedItem.trades,
          [tradeName]: existingQuantityValue.quantity,
        };
      }

      return updatedItem;
    });

    await launchParamsService.createLaunchParam(transformedPayload);

    getLaunchParams();

    dispatch({
      type: StressTestJobsActions.RemoveExistingQuantity,
    });

    Notification.success({ title: 'Successfully changed quantity' });
  }, [existingQuantityValue, manualTestData]);

  const isDirty =
    accountName === existingQuantityValue.accountName &&
    date === existingQuantityValue.date &&
    tradeName === existingQuantityValue.tradeName &&
    quantity !== existingQuantityValue.quantity;

  return (
    <Tooltip title="Save quantity">
      <IconButton
        disabled={!isDirty || isEmpty(existingQuantityValue)}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={18}
        id="saveQuantity"
        name="saveQuantity"
        onClick={handleSaveQuantity}
      />
    </Tooltip>
  );
};

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateCountOfPages, useTableData } from 'react-ui-kit-exante';

import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import {
  getStartTime,
  normalizeDaysOfWeek,
} from 'pages/StressTestSettings/helpers';
import { StressTestSettingsRoutes } from 'pages/StressTestSettings/routes';
import { periodicJobService } from 'resources';
import type {
  TJobArgumentsWithAccount,
  TJobArgumentsWithoutAccount,
  TPeriodicJob,
  TPeriodicJobState,
  TPeriodicJobUpdate,
} from 'types';

import { getColumns } from '../columns';
import { getDefaultPagination } from '../constants';
import type {
  TGetRuntimeSettingsProps,
  TUseRuntimeSettingsProps,
} from '../types';

export const useRuntimeSettings = ({
  dispatch,
  tableId,
}: TUseRuntimeSettingsProps) => {
  const navigate = useNavigate();

  const getRuntimeSettings = useCallback(
    ({ params: { page, sorting, ...rest } }: TGetRuntimeSettingsProps) => {
      return periodicJobService.fetchPeriodicJobList({
        ...rest,
      });
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getRuntimeSettings },
      pagination: {
        getDefaultPagination,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getRuntimeSettings, tableId],
  );

  const {
    data,
    fetchData: refetch,
    isLoading,
    limit,
    page,
    setLimit,
    setPage,
  } = useTableData<TPeriodicJobState>(tableDataArgs);

  const columns = useMemo(() => getColumns(), []);

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const handleUpdateSetting = useCallback(
    async (_: TPeriodicJob, updated: TPeriodicJob) => {
      const { actions, id, ...rest } = updated;

      let jobArguments:
        | TJobArgumentsWithAccount[]
        | TJobArgumentsWithoutAccount;

      if (rest.account) {
        jobArguments = [
          {
            account: rest.account,
            date: null,
            v_trades: {},
          },
        ];
      } else {
        jobArguments = {
          date: null,
        };
      }

      const payload: TPeriodicJobUpdate = {
        arguments: jobArguments,
        comment: rest.comment,
        day_of_week: normalizeDaysOfWeek(rest.runOnDays),
        hour: getStartTime(rest.startTime, 0),
        is_enabled: rest.isEnabled,
        minute: getStartTime(rest.startTime, 1),
        name: rest.name,
      };

      await periodicJobService.updatePeriodicJob(id, payload);

      refetch();
    },
    [refetch],
  );

  const handleDeleteSetting = useCallback((rowValue: TPeriodicJob) => {
    const { id } = rowValue;

    dispatch({
      type: StressTestSettingsActions.RuntimeSettingRemoveModal,
      payload: {
        id,
        show: true,
      },
    });
  }, []);

  const handleAddRuntimeSetting = useCallback(() => {
    navigate(StressTestSettingsRoutes.RUNTIME_SETTING_ADD);
  }, [navigate]);

  return {
    columns,
    data,
    isLoading,
    handleAddRuntimeSetting,
    handleDeleteSetting,
    handleUpdateSetting,
    refetch,
    serverPaginationProps,
  };
};

import { StressTestJobsState } from './types';

export const initialState: StressTestJobsState = {
  errors: '',

  ready: false,
  dirty: false,

  fetchManualTestStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  manualTestData: [],

  manualTestAddAccountModal: false,
  manualTestRemoveAccountModal: {
    accountName: '',
    date: '',
    show: false,
  },
  manualTestRemoveTradeModal: {
    accountName: '',
    date: '',
    show: false,
    tradeName: '',
  },

  accountDateValue: {
    accountName: undefined,
    date: '',
    initialDate: '',
  },
  existingQuantityValue: {
    accountName: '',
    date: '',
    quantity: null,
    tradeName: '',
  },
  virtualPositionValue: {
    accountName: '',
    date: '',
    quantity: null,
    tradeName: '',
  },

  stateRemoveJobModal: {
    id: '',
    show: false,
  },
};

import { StressTestJobsState } from '../types';

export const onRemoveExistingQuantityValue = (
  state: StressTestJobsState,
): StressTestJobsState => {
  return {
    ...state,
    existingQuantityValue: {
      accountName: '',
      date: '',
      quantity: null,
      tradeName: '',
    },
  };
};

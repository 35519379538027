import { StressTestJobsState, TRemoveAccount } from '../types';

export const onManualTestToggleRemoveAccountModal = (
  state: StressTestJobsState,
  payload: TRemoveAccount,
): StressTestJobsState => ({
  ...state,

  manualTestRemoveAccountModal: payload,
});

import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_TRADE_VALUE } from 'constants/manualTest';
import type { TManualTest } from 'types';

import { ActionsCell } from './ActionsCell';
import { DateCell } from './DateCell';
import { ExistingQuantity, VirtualPositionQuantity } from './QuantityCell';
import { VirtualPosition } from './VirtualPosition';

type TGetColumnsProps = {
  getLaunchParams: () => Promise<void>;
};

export const getColumns = ({
  getLaunchParams,
}: TGetColumnsProps): IColumn<TManualTest>[] => [
  {
    Header: 'Account NR/Symbol',
    id: 'name',
    accessor: 'name',
    disableSortBy: true,
    minWidth: 275,
    maxWidth: 300,
    tooltip: 'off',
    Cell: ({ row: { original } }: ICellValue<TManualTest>) => {
      const { accountName, date, name, quantity, tradeName } = original;

      if (tradeName === DEFAULT_TRADE_VALUE) {
        return (
          <VirtualPosition
            accountName={accountName || ''}
            date={date || ''}
            quantity={quantity}
            tradeName={tradeName}
          />
        );
      }

      return name || 'All Accounts';
    },
  },
  {
    Header: 'Date',
    id: 'date',
    accessor: 'date',
    disableSortBy: true,
    tooltip: 'off',
    Cell: ({ row: { original } }: ICellValue<TManualTest>) => {
      const { date, name, quantity, tradeName } = original;

      if (quantity !== null || tradeName === DEFAULT_TRADE_VALUE) {
        return null;
      }

      return <DateCell accountName={name || ''} date={date || ''} />;
    },
  },
  {
    Header: 'Quantity',
    id: 'quantity',
    accessor: 'quantity',
    disableSortBy: true,
    shouldExpand: false,
    tooltip: 'off',
    Cell: ({ row: { original } }: ICellValue<TManualTest>) => {
      const { accountName, date, name, quantity, tradeName } = original;

      if (tradeName === DEFAULT_TRADE_VALUE) {
        return (
          <VirtualPositionQuantity
            accountName={accountName || ''}
            date={date || ''}
            quantity={quantity}
          />
        );
      }

      return (
        <ExistingQuantity
          accountName={accountName || ''}
          date={date || ''}
          quantity={quantity}
          tradeName={name || ''}
        />
      );
    },
  },
  {
    Header: 'Actions',
    id: 'actions',
    accessor: 'actions',
    disableSortBy: true,
    disableFilters: true,
    shouldExpand: false,
    Cell: ({ row: { original } }: ICellValue<TManualTest>) => {
      const { accountName, date, name, quantity } = original;

      return (
        <ActionsCell
          accountName={accountName || ''}
          date={date || ''}
          getLaunchParams={getLaunchParams}
          quantity={quantity}
          tradeName={name || ''}
        />
      );
    },
  },
];

import dayjs from 'dayjs';
import { useCallback, useContext, useState } from 'react';
import { InputDatePicker, Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT_D_M_Y } from 'constants/common';
import {
  StressTestJobsActions,
  StressTestJobsContext,
} from 'pages/StressTestJobs/context';

type TDateCellProps = {
  accountName: string;
  date: string;
};

export const DateCell = ({ accountName, date }: TDateCellProps) => {
  const [currentValue, setCurrentValue] = useState<string>(
    date || dayjs(new Date()).format(DATE_FORMAT_D_M_Y),
  );

  const { dispatch } = useContext(StressTestJobsContext);

  const handleChangeExisting = useCallback(
    (value: Date | null) => {
      if (!value) {
        Notification.warning({
          title: 'Date is required field',
        });

        return;
      }

      const formatted = dayjs(value).format(DATE_FORMAT_D_M_Y);

      if (formatted !== 'Invalid Date' && formatted !== currentValue) {
        setCurrentValue(formatted);

        dispatch({
          type: StressTestJobsActions.SetDateValue,
          payload: {
            accountName,
            date: formatted,
            initialDate: date,
          },
        });
      }
    },
    [currentValue],
  );

  return (
    <InputDatePicker
      dateFormat="dd.MM.yyyy"
      inputProps={{
        label: 'Date',
        size: 'small',
      }}
      onChange={handleChangeExisting}
      selected={currentValue}
    />
  );
};

import { PopoverProps } from '@mui/material';
import {
  DatePicker as DatePickerMUI,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import { DEFAULT_LOCALE } from '../../../../consts';
import { getDateLocale } from '../../../../helpers/getDateLocale';
import { CalendarIcon } from '../../../Icons';
import { Input } from '../../Input/Input';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_MASK,
  INPUT_DATE_PICKER_DATA_TEST_ID,
} from '../constants';
import { getCalendarStyles } from '../helpers';

import { IUIDatePickerProps } from './types';

/**
 * This component is deprecated.
 * Use `InputDatePicker` instead.
 *
 * @deprecated
 */
export const DatePicker: FC<IUIDatePickerProps> = ({
  controlProps,
  iconLeft,
  inputFormat = DEFAULT_DATE_FORMAT,
  label,
  locale = DEFAULT_LOCALE,
  mask = DEFAULT_DATE_MASK,
  onChange,
  size,
  value = null,
  ...rest
}) => {
  const isHiddenLabel = size === 'small';

  return (
    <DefaultThemeProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getDateLocale(locale)}
      >
        <DatePickerMUI
          onChange={onChange}
          renderInput={(params) => (
            <Input
              iconLeft={iconLeft}
              size={size}
              data-test-id={INPUT_DATE_PICKER_DATA_TEST_ID}
              {...controlProps}
              {...params}
              error={params.error || controlProps?.error}
            />
          )}
          value={value}
          label={isHiddenLabel ? '' : label}
          components={{ OpenPickerIcon: CalendarIcon }}
          inputFormat={inputFormat}
          mask={mask}
          PopperProps={{ sx: getCalendarStyles as PopoverProps['sx'] }}
          {...rest}
        />
      </LocalizationProvider>
    </DefaultThemeProvider>
  );
};

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Notification,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { StressTestJobsActions } from 'pages/StressTestJobs/context';
import { jobService } from 'resources';
import type { TJobState, TJobStateServer } from 'types';
import { getThreeDaysDefaultFilter, resetTablePage } from 'utils';

import { getColumns } from '../columns';
import {
  STATUS_FAILED,
  getDefaultPagination,
  getDefaultSorting,
} from '../constants';
import { getAdditionalFilters } from '../filters';
import type {
  TGetState,
  THandleCellClickProps,
  TUseStateComponentHookProps,
} from '../types';

export const useStateComponentHook = ({
  dispatch,
  setIsRefreshBtnClicked,
  state,
  tableId,
}: TUseStateComponentHookProps) => {
  const navigate = useNavigate();

  const getJobStates = useCallback(async ({ params }: TGetState) => {
    const { sorting, ...rest } = params;

    const data = await jobService.fetchJobsList(rest);

    return data;
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getJobStates },
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getJobStates, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
    filters,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
  } = useTableData<TJobStateServer>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: (pageSize: number) => {
        resetTablePage(tableId);
        setLimit(pageSize);
      },
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getThreeDaysDefaultFilter(),
      }),
    [filters, removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const handleDeleteJob = useCallback(
    (rowValue: unknown) => {
      const { id } = rowValue as TJobState;

      dispatch({
        type: StressTestJobsActions.StateRemoveJobModal,
        payload: {
          id,
          show: true,
        },
      });
    },
    [state],
  );

  const handleClickOnRefresh = useCallback(() => {
    setIsRefreshBtnClicked(true);

    return refetch();
  }, [refetch]);

  const handleCellClick = useCallback(
    ({ row }: THandleCellClickProps) => {
      const { original } = row;
      const { id, status } = original;

      if (status === STATUS_FAILED) {
        Notification.warning({
          title: `There are no results for job ${id}\nJob has status "Failed"`,
        });

        return;
      }

      if (id) {
        navigate(`${ROUTES.STRESS_TEST_RESULT}?jobId=${id}&groupBy=underlying`);
      }
    },
    [navigate],
  );

  return {
    columns,
    data,
    filteringProps,
    handleCellClick,
    handleClickOnRefresh,
    handleDeleteJob,
    isLoading,
    refetch,
    serverPaginationProps,
  };
};

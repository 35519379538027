import { StressTestJobsState } from '../types';

export const onFetchManualTestStart = (
  state: StressTestJobsState,
): StressTestJobsState => ({
  ...state,

  manualTestData: [],

  fetchManualTestStatus: {
    error: null,
    pending: true,
    succeed: false,
  },
});

import { createBreakpoints } from '@mui/system';

import { KitThemeExtensions } from './theme.types';

const darkThemeBreakpoints = createBreakpoints({
  values: {
    xs: 0,
    tablet: 512,
    sm: 768,
    md: 1024,
    lg: 1280,
    lr: 1440,
    xl: 1920,
  },
});

export const darkThemeValues: KitThemeExtensions = {
  color: {
    bg: {
      basic: '#0F1212', // Background Global
      primary: '#141919', // Background Block
      secondary: '#2F373A', // to do replace & remove bg.secondary -> controls.state.focus
      promo: '#4E5D60', // to do change in CodeEditor & remove
      source: '#0B2215', // to do remove & replace with Controls State Source
      warning: '#5F4004', // to do remove & replace with Controls State Warning
      radical: '#471D1F', // to do remove & replace with Controls State Radical
    },
    button: {
      primary: {
        // to do remove block
        gr: {
          default: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
          focus: '#01803A',
          hover: 'linear-gradient(180deg, #01C056 0%, #01803A 100%)',
        },
        bg: {
          default: '#209F59', // Primary Button Default
          hover: '#28C76F', // Primary Button Hover
          focus: '#136036', // Primary Button Focus
        },
        typo: {
          default: '#FFFFFF', // Base Inverse
          hover: '#FFFFFF', // Base Inverse
          focus: '#FFFFFF', // Base Inverse
          disabled: '#FFFFFF', // Base Inverse
        },
        // to do remove block
        corner: {
          default: '#01662E',
        },
      },
      radical: {
        // to do remove block
        gr: {
          default: 'linear-gradient(180deg, #BF3B4B 0%, #8B2D2D 100%)',
          focus: '#8B2D2D',
          hover: 'linear-gradient(180deg, #CA4747 0%, #A63636 100%)',
        },
        bg: {
          default: '#DB4154', // Radical Button Default
          hover: '#F6475D', // Radical Button Hover
          focus: '#8D3038', // Radical Button Focus
        },
        // to do remove block & replace with typo.inverse
        typo: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
          focus: '#E6CCCC',
          disabled: '#FFFFFF',
        },
        // to do remove block
        corner: {
          default: '#6F2626',
        },
      },
      secondary: {
        // to do remove block
        gr: {
          default: 'linear-gradient(180deg, #F0F0F0 0%, #E4E4E4 100%)',
          focus: '#E6E6E6',
          hover: 'linear-gradient(180deg, #F9F9F9 0%, #F0F0F0 100%)',
        },
        bg: {
          default: '#209F59', // Secondary Button Default
          hover: '#209F59', // Secondary Button Hover
          focus: '#222627', // Secondary Button Focus
        },
        // to do remove block
        typo: {
          default: '#4E5D60',
          hover: '#252C2E',
          focus: '#BABABA',
          disabled: '#4E5D60',
        },
        // to do remove block
        corner: {
          default: '#D3D3D3',
        },
      },
      warning: {},
    },
    checkbox: {
      primary: {
        checked: {
          bg: '#209F59', // Button Primary Default
          border: {
            default: '#209F59', // Base Primary Action
            focus: '#209F59', // Base Primary Action
            hover: '#209F59', // Base Primary Action
          },
          //  to do remove block
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
        nonChecked: {
          bg: '#141919', // Background Block
          border: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
          // to do remove block
          boxShadow: {
            default: 'none',
            hover: 'none',
            focus: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
      },
      warning: {
        checked: {
          bg: '#EC9F0B', // Warning Controls Default
          border: {
            default: '#EC9F0B', // Warning Controls Default
            focus: '#EC9F0B', // Warning Controls Default
            hover: '#F4B24D', // Warning Controls Hover
          },
          //  to do remove block
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(255, 176, 26, 0.24), inset 0 4.8px 9.6px rgba(255, 229, 181, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
        nonChecked: {
          bg: '#141919', // Background Block
          border: {
            default: '#EC9F0B', // Warning Controls Default
            focus: '#EC9F0B', // Warning Controls Default
            hover: '#F4B24D', // Warning Controls Hover
          },
          // to do remove block
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
      },
      radical: {
        checked: {
          bg: '#DB4154', // Button Radical Default
          border: {
            default: '#DB4154', // Button Radical Default
            focus: '#DB4154', // Button Radical Default
            hover: '#F6475D', // Button Radical Hover
          },
          //  to do remove block
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(191, 59, 74, 0.24), inset 0 4.8px 9.6px rgba(191, 59, 74, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
        nonChecked: {
          bg: '#141919', // Background Block
          border: {
            default: '#DB4154', // Button Radical Default
            hover: '#F6475D', // Button Radical Hover
            focus: '#DB4154', // Button Radical Default
          },
          // to do remove block
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#A0AFB3', // Base Secondary Action
            hover: '#B8C3C6', // Base Secondary
            focus: '#FFFFFF', // Base Primary
          },
        },
      },
    },
    controls: {
      gr: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)', // remove & replace with button/primary/default in Switch
    },
    defaultBoxShadow: '0 4px 8px 0 rgba(37, 44, 46, 0.1)', // move to Table block
    dropdown: {
      bg: {
        default: '#242A2C', // Controls State Default
      },
      border: '#2F373A', // Controls State Border
      error: '#F6475D', // Base Radical
      list: {
        bg: {
          focus: '#2F373A', // Controls State Focus
          hover: '#2F373A', // Controls State Hover
        },
      },
      chip: {
        bg: '#2F373A', // Controls State Filled
      },
    },
    icon: {
      action: '#209F59', // Base Primary Action
      ghost: '#71878D', // Base Ghost
      inverse: '#FFFFFF', // Base Inverse
      primary: '#FFFFFF', // Base Primary
      promo: '#B8C3C6', // Base Secondary
      radical: '#F6475D', // Base Radical
      secondary: '#A0AFB3', // Base Secondary Action
      source: '#339961', // Base Source
      warning: '#EC9F0B', // Base Warning
    },
    indicator: {
      bg: {
        basic: '#2F373A', // Controls State Filled
      },
    },
    circularProgress: {
      background: '#0F1212', // Background Global
      foreground: '#339961', // Base Source
    },
    input: {
      bg: {
        default: '#242A2C', // Controls State Default
        hover: '#2F373A', // Controls State Hover
        focus: '#2F373A', // Controls State Focus
      },
      border: '#2F373A', // Controls State Border
      error: '#F6475D', // Base Radical
      warning: '#EC9F0B', // Base Warning
      transparentWarning: 'rgba(236, 159, 11, 0.3)', // remove & replace in Switch & ToggleButton components
    },
    modal: {
      bg: '#0F1212', // Background Modal
    },
    line: {
      primary: '#212829', // Border Secondary
    },
    notifications: {
      success: '#339961', // Base Source
      error: '#F6475D', // Base Radical
      warning: '#EA9C0B', // Base Warning
    },
    typo: {
      action: '#209F59', // Base Primary Action
      ghost: '#71878D', // Base Ghost
      inverse: '#FFFFFF', // Base Inverse
      primary: '#FFFFFF', // Base Primary
      promo: '#B8C3C6', // Base Secondary
      radical: '#F6475D', // Base Radical
      secondary: '#A0AFB3', // Base Secondary Action
      source: '#339961', // Base Source
      warning: '#EC9F0B', // Base Warning
    },
    table: {
      bg: {
        basic1: '#121616', // Table Base 1
        basic2: '#141919', // Table Base 2
        radical: '#3C1A1B', // Table Radical Default
        source: '#0B2215', // Table Source Default
        hover: '#2F373A', // Table Hover
        selected: '#CBE3CD', // to do Ask designers
      },
      boxShadow: {
        basic1: 'inset 0 1px 0 #212829', // Border Secondary
      },
      // to do remove block
      header: {
        default: '#818C99',
      },
    },
    datepicker: {
      background: '#141919', // Background Block
      colorWeek: '#FFFFFF', // Base Primary
      colorDay: '#A0AFB3', // Base Secondary Action
      daySelected: '#2F373A', // Controls State Focus
      dayHover: '#2F373A', // Controls State Hover
      dayBorder: '#2F373A', // Controls State Focus
    },
    loader: {
      color: '#A0AFB3', // Base Secondary Action
    },
    // to do: component Tree requires design and further refactoring according to it
    tree: {
      hover: '#F5F5F5',
      focused: '#007f3933',
      expanded: '#ECF5F0',
    },
  },
  effect: {
    controls: {
      warning:
        'inset 0 -4.8px 9.6px rgba(236, 159, 11, 0.24), inset 0 4.8px 9.6px rgba(236, 159, 11, 0.41)',
      inter:
        'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
      drop: '0 2.32257px 6.19352px rgba(0, 0, 0, 0.15)',
    },
  },
  font: {
    main: 'Inter, sans-serif',
    header: 'FoxSansPro, sans-serif',
  },
  size: {
    loader: {
      md: '16px',
      lg: '32px',
    },
    text: {
      sm: '12px',
      md: '13px',
      lg: '15px',
    },
  },
  breakpoints: darkThemeBreakpoints,
};

import { styled } from 'theme';

import { Input } from '../Input/Input';

const closeIconHTML = `<span><svg width='100%' height='100%' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M24 8L8 24M8 8l16 16' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'></path></svg></span>`;

function getSvgString() {
  const div = document.createElement('div');
  div.innerHTML = closeIconHTML;

  // remove span with saving svg inside
  const firstSpan = div.querySelector('span');
  if (!firstSpan) {
    return '';
  }
  while (firstSpan.firstChild) {
    firstSpan.parentNode?.insertBefore(firstSpan.firstChild, firstSpan);
  }
  firstSpan.parentNode?.removeChild(firstSpan);

  return div.innerHTML;
}

export const SearchInputStyled = styled(Input)(() => ({
  '& input::-webkit-search-cancel-button': {
    position: 'relative',
    cursor: 'pointer',
    appearance: 'none',
    height: '16px',
    width: '16px',
    color: 'black',
    backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
      getSvgString(),
    )}")`,
  },
}));

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateCountOfPages, useTableData } from 'react-ui-kit-exante';

import { EXCLUSION_TYPE } from 'pages/StressTestSettings/constants';
import { StressTestSettingsActions } from 'pages/StressTestSettings/context';
import { StressTestSettingsRoutes } from 'pages/StressTestSettings/routes';
import { excludeService } from 'resources';
import type { TExclude, TExcludeServerResponse } from 'types';

import { getColumns } from '../columns';
import { getDefaultPagination } from '../constants';
import type { GetParametersProps, TUseExcludeProps } from '../types';

export const useExclude = ({ dispatch, tableId }: TUseExcludeProps) => {
  const navigate = useNavigate();

  const getExcludeList = useCallback(
    ({ params: { page, sorting, ...rest } }: GetParametersProps) => {
      return excludeService.fetchExcludeList({
        ...rest,
      });
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getExcludeList },
      pagination: {
        getDefaultPagination,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getExcludeList, tableId],
  );

  const {
    data,
    fetchData: refetch,
    filters,
    isLoading,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
  } = useTableData<TExcludeServerResponse>(tableDataArgs);

  const exclusionTypeOptions = useMemo(() => {
    return EXCLUSION_TYPE.map((type: string) => {
      return {
        value: type,
        label: type,
      };
    });
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        exclusionTypeOptions,
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [exclusionTypeOptions, removeFilter, setFilter],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const filteringProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const handleUpdateExclude = useCallback(
    async (_: TExclude, updated: TExclude) => {
      const { actions, id, ...payload } = updated;

      await excludeService.updateExclude(id, payload);

      refetch();
    },
    [refetch],
  );

  const handleDeleteExclude = useCallback((rowValue: TExclude) => {
    const { id } = rowValue;

    dispatch({
      type: StressTestSettingsActions.RemoveExcludeModal,
      payload: {
        id,
        show: true,
      },
    });
  }, []);

  const handleAddStressTestExclude = useCallback(() => {
    navigate(StressTestSettingsRoutes.STRESS_EXCLUDE_ADD);
  }, [navigate]);

  return {
    columns,
    data,
    filteringProps,
    handleAddStressTestExclude,
    handleDeleteExclude,
    handleUpdateExclude,
    isLoading,
    refetch,
    serverPaginationProps,
  };
};
